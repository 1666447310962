import React from "react";

import NavbarLink from "../NavbarLink";

function ZonnepanelenSubNavbar() {
    return (
        <>
            <NavbarLink to="/zonnepanelen/" exact>
                <i className="fa-solid fa-house fa-fw mr-2" />
                Overzicht
            </NavbarLink>
            <NavbarLink to="/producten/" exact>
                <i className="fa-solid fa-solar-panel fa-fw mr-2" />
                Producten
            </NavbarLink>
            <NavbarLink to="/zonnepanelen/voordelen/" exact>
                <i className="fa-solid fa-thumbs-up fa-fw mr-2" />
                Voordelen
            </NavbarLink>
            <NavbarLink to="/zonnepanelen/installeren/" exact>
                <i className="fa-solid fa-tools fa-fw mr-2" />
                Installatie
            </NavbarLink>
            <NavbarLink to="/zonnepanelen/zakelijk/" exact>
                <i className="fa-solid fa-briefcase fa-fw mr-2" />
                Zakelijk
            </NavbarLink>
            <NavbarLink to="/zonnepanelen/tuinders/" exact>
                <i className="fa-solid fa-leaf fa-fw mr-2" />
                Tuinders
            </NavbarLink>
            <NavbarLink to="/zonnepanelen/btw/" exact>
                <i className="fa-solid fa-circle-euro fa-fw mr-2" />
                0% btw
            </NavbarLink>
            <NavbarLink to="/zonnepanelen/subsidie/" exact>
                <i className="fa-solid fa-buildings fa-fw mr-2" />
                Zakelijke SDE Subsidie
            </NavbarLink>
        </>
    );
}

export default React.memo(ZonnepanelenSubNavbar);
