import React, { useRef, useState, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

const SwiperComponent = ({ title, slides, config }) => {
    const swiperRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    }, []);

    const handleNext = useCallback(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    }, []);

    const [isEnd, setIsEnd] = useState(false);
    const [isBeginning, setIsBeginning] = useState(true);

    const handleSlideChange = useCallback(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsEnd(swiperRef.current.swiper.isEnd);
            setIsBeginning(swiperRef.current.swiper.isBeginning);
        }
    }, []);

    const swiperConfig = config || {
        slidesPerView: 4,
        spaceBetween: 50,
        pagination: { clickable: true },
        navigation: true,
        loop: false,
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            480: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },
    };

    return (
        <div className="swiper-container">
            <div className="swiper-title">
                <h2 className="title">{title}</h2>
            </div>
            <Swiper ref={swiperRef} {...swiperConfig} onSlideChange={handleSlideChange}>
                {slides.map((slide, index) => (
                    <SwiperSlide key={slide.id} className="fixed-size-slide">
                        <div className="swiper-slide-content">
                            <div className="text-content">
                                <div className="index">{index + 1}</div>
                                <div className="text-container">
                                    <h3 className="title">{slide.title}</h3>
                                    <p className="text">{slide.description}</p>
                                </div>
                            </div>
                            <div className="fixed-size-image">
                                <img src={slide.image} alt={slide.title} />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="custom-pagination">
                <i className="fa-solid fa-chevron-left fa-xl" style={isBeginning ? { opacity: 0, cursor: "default" } : {}} onClick={handlePrev}></i>
                <i className="fa-solid fa-chevron-right fa-xl" style={isEnd ? { opacity: 0, cursor: "default" } : {}} onClick={handleNext}></i>
            </div>
        </div>
    );
};

export default React.memo(SwiperComponent);
