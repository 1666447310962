import React from "react";
// import moment from "moment";
import Helmet from "../components/Helmet";
import HomepageImageText from "../components/HomepageImageText";
import HomepageCTABlock from "../components/HomepageCTABlock";
import HomepageBanner from "../components/HomepageBanner";
import NavbarSettings from "../components/navbar/NavbarSettings";

import banner from "./../img/solar-banner.jpg";
import phoneMockupFilled from "../img/ems/phone-mockup-filled.png";
import particulierenImg from "../img/home/particulieren.jpg";
import thuisbatterijImg from "../img/battery/byd-lvs.png";
import acWallbox from "../img/charger/ac-wallbox.png";
// import werkenBijSmith from "../img/home/werkenbijsmith.jpg";

function Home() {
    // let hour = moment().hour();
    // let opened = hour >= 8 && hour < 17;
    // let weekDay = moment().isoWeekday();
    // if (weekDay === 6 || weekDay === 7) opened = false;

    return (
        <React.Fragment>
            <Helmet/>

            <NavbarSettings>
                <HomepageBanner
                    title="Smith Solar B.V."
                    subtitle="Specialist in zonne-energie"
                    image={banner}
                    imageAlt="Foto van zonnepanelen"
                    offerteWidget
                />
            </NavbarSettings>

            <div className="container-white-background">
                <div className="container">
                    <h1>Specialist in zonne-energie</h1>
                    <div className="article-open">
                        Wil jij bijdragen aan een duurzame toekomst met groene energie? Ontdek dan de voordelen van zonne-energie met de hoogwaardige
                        producten van Smith Solar. Wij zijn gespecialiseerd in groene energie zoals zonnepanelen, energieopslag en laadpalen. Met een
                        divers aanbod bieden we voor ieder wat wils.
                    </div>
                </div>
            </div>
            <div className="container">
                <HomepageImageText
                    title="Zonnepanelen"
                    text="Smith Solar B.V. is jouw betrouwbare partner in zonnepanelen. We hebben jarenlange ervaring op het gebied van zonnepanelen en werken uitsluitend met hoogwaardige merken en materialen. Zonnepanelen dragen bij aan een groenere toekomst en hebben allerlei voordelen voor zowel je financiën als levensstijl. Wek vandaag nog je eigen stroom op en kies voor de zonnepanelen van Smith Solar!"
                    buttonText="Lees meer over onze zonnepanelen"
                    buttonLink="/zonnepanelen/"
                    img={particulierenImg}
                    imgAlt="Afbeelding van zonnepanelen"
                    isReverse={false}
                    coverImage={true}
                />
                <HomepageImageText
                    title="Thuisbatterij"
                    text="Met een thuisbatterij van Smith Solar ben je niet alleen zelfvoorzienend, maar kun je ook nog eens profiteren van dynamische tarieven. Een thuisbatterij wordt gebruikt om overtollige energie die overdag wordt gegenereerd op te slaan en beschikbaar te stellen als de vraag naar elektriciteit piekt. Zo ben je minder afhankelijk van het reguliere energienet. Onze thuisbatterijen voldoen aan de hoogste normen op het gebied van kwaliteit en efficiëntie."
                    buttonText="Kom alles te weten over thuisbatterijen"
                    buttonLink="/thuisbatterij/"
                    img={thuisbatterijImg}
                    imgAlt="Afbeelding van thuisbatterij"
                    isReverse={true}
                />
                <HomepageImageText
                    title="Laadpalen"
                    text="Heb je een elektrische auto? Dan is een laadpaal van Smith Solar B.V. een waardevolle toevoeging voor jou. Hiermee gebruik je opgewekte stroom om je auto milieuvriendelijk op te laden. Een laadpaal is essentieel in de wereld van elektrisch rijden en biedt optimaal gebruiksgemak in het dagelijkse leven. Onze specialisten bieden specifiek advies voor jouw situatie en auto, zodat je altijd de beste oplossing krijgt."
                    buttonText="Bekijk onze laadpalenpagina voor meer info"
                    buttonLink="/laadpalen/"
                    img={acWallbox}
                    imgAlt="Afbeelding van laadpaal"
                    isReverse={false}
                />
            </div>

            <div className="container-blue-background">
                <div className="container">
                    <HomepageImageText
                        title="Energie Management Systeem"
                        text="Met ons Energy Management Systeem (EMS) stuur je jouw apparaten in huis op een slimme manier aan en heb je volledige controle over je energieverbruik. Schakel je zonnepanelen uit, handel met je thuisbatterij en stem automatisch het energieverbruik af voor maximale besparing. Het is de toekomst van energiebeheer, beschikbaar bij Smith Solar."
                        buttonText="Ontdek alles over EMS"
                        buttonLink="/ems/"
                        img={phoneMockupFilled}
                        isReverse={false}
                        underLinedTitle={false}
                    />
                </div>
            </div>
            <HomepageCTABlock
                title="Neem contact op"
                text="Heb je interesse in Gridsense, zonnepanelen, laadpalen of thuisbatterijen? Ons team helpt je graag verder met advies en installatie op maat."
                buttonText="Neem contact op"
                buttonLink="/contact/"
                img={particulierenImg}
            />
        </React.Fragment>
    );
}

export default Home;
