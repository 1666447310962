import React from "react";

import Helmet from "../components/Helmet";
import CallToAction from "../components/CallToAction";
import NavbarSettings from "../components/navbar/NavbarSettings";
import SubPageBanner from "../components/SubPageBanner";
import banner from "../img/solar-banner.jpg";
import HomepageImageText from "../components/HomepageImageText";

function BTW() {
    return (
        <React.Fragment>
            <Helmet
                title="0% btw op zonnepanelen"
                description="Vanaf 1 januari 2023 hoeft voor zonnepanelen voor woonhuizen geen btw meer betaald te worden. Lees hier meer."
            />

            <NavbarSettings>
                <SubPageBanner title="0% btw op zonnepanelen" image={banner} imgAlt="Foto van zonnepanelen" />
            </NavbarSettings>

            <div className="container-orange-background">
                <div className="container">
                    <HomepageImageText 
                        title="Van 21% naar 0% btw"
                        text="De btw op zonnepanelen is vanaf 1 januari 2023 afgeschaft. De btw gaat omlaag van 21% naar 0%. Dit zogenoemde nultarief geldt
                        alleen als de zonnepanelen worden geïnstalleerd op woningen of bijgebouwen van een woning, zoals een schuur."
                        img={banner}
                        imgAlt="Foto van zonnepanelen"
                        coverImage={true}
                    />
                </div>
            </div>
            <div className="container-white-background">
                <div className="container container-sm">

                    <h2>Minder administratie voor particulieren en de Belastingdienst</h2>

                    <p>
                        Door de btw te verlagen van 21% naar 0%, hoeven particulieren bij de aankoop van zonnepanelen, de btw niet meer terug te vragen
                        bij de Belastingdienst. Dat scheelt hen en de Belastingdienst werk. Ook hoopt het kabinet dat de maatregel meer particulieren
                        stimuleert om zonnepanelen te nemen.
                    </p>
                </div>
            </div>

            <div className="container container-sm">

                <h2>Moet ik straks 21% of 0% btw betalen?</h2>

                <p>
                    Om te bepalen of er 21% of 0% btw in rekening gebracht wordt, wordt gekeken naar of het pand een woonfunctie heeft. Hierin is het
                    BAG register van het Kadaster leidend.
                </p>

                <p>
                    Open de BAG viewer via onderstaande knop en zoek uw adres op. Nadat u uw adres heeft opgezocht komt aan de rechter kant van het
                    scherm een balk met info over uw pand te staan. Zoek op wat onder <b>Verblijfsobject</b> > <b>Gebruiksdoel</b> staat en vergelijk
                    dat met wat hieronder staat.
                </p>
            </div>

            <div className="container">
                <div className="mb-4 d-flex justify-content-center">
                    <a href="https://bagviewer.kadaster.nl" target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                        BAG viewer
                    </a>
                </div>

                <div className="row text-center">
                    <div className="col-md-4">
                        <div className="card mb-3">
                            <div className="card-body">
                                <h3 className="card-title" style={{fontSize: "1.5rem"}}>Alleen woonfunctie</h3>
                                <p className="card-text">
                                    U betaald
                                    <br />
                                    <b className="text-primary" style={{ fontSize: "1.5rem" }}>
                                        0% btw
                                    </b>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mb-3">
                            <div className="card-body">
                                <h3 className="card-title" style={{fontSize: "1.5rem"}}>Woonfunctie + iets anders</h3>
                                <p className="card-text">
                                    U betaald
                                    <br />
                                    <b className="text-primary" style={{ fontSize: "1.5rem" }}>
                                        0% btw
                                    </b>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mb-3">
                            <div className="card-body">
                                <h3 className="card-title" style={{fontSize: "1.5rem"}}>Geen woonfunctie</h3>
                                <p className="card-text">
                                    U betaald
                                    <br />
                                    <b className="text-primary" style={{ fontSize: "1.5rem" }}>
                                        21% btw
                                    </b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <p className="text-center">
                    Voor een uitgebreidere uitleg verwijzen we u naar de{" "}
                    <a
                        href="https://www.belastingdienst.nl/wps/wcm/connect/nl/btw/content/wetsvoorstel-zonnepanelen-nultarief-2023"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        informatie pagina van de Belastingdienst
                    </a>
                    .
                </p>
            </div>
            <CallToAction />
        </React.Fragment>
    );
}

export default React.memo(BTW);
