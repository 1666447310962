import React from "react";

import Helmet from "../../components/Helmet";
import CallToAction from "../../components/CallToAction";
import NavbarSettings from "../../components/navbar/NavbarSettings";
import SubPageBanner from "../../components/SubPageBanner";
import HomepageImageText from "../../components/HomepageImageText";

import banner from "../../img/solar-banner.jpg";
import particulierenImg from "../../img/home/particulieren.jpg";

function ZonnepanelenVoordelen() {
    return (
        <React.Fragment>
            <Helmet
                title="Voordelen van zonnepanelen"
                description="Gebruik maken van zonne-energie levert natuurlijk een groot voordeel op voor je energierekening. Zelfs bij bewolkt weer wordt er stroom opgewekt. Maar zonnepanelen leveren nog veel meer voordelen op. Hier alle voordelen op een rijtje."
            />
            <NavbarSettings>
                <SubPageBanner
                    title="Voordelen van zonnepanelen"
                    image={banner}
                    imageAlt="Foto van zonnepanelen"
                />
            </NavbarSettings>

            <div className="container-orange-background">
                <div className="container">
                    <HomepageImageText
                        title="Maak gebruik van de voordelen"
                        text="Gebruik maken van zonne-energie levert natuurlijk een groot voordeel op voor je energierekening. 
                            Zelfs bij bewolkt weer wordt er stroom opgewekt. Maar zonnepanelen leveren nog veel meer voordelen op. 
                            Hieronder een overzicht van alle voordelen van zonnepanelen."
                        img={particulierenImg}
                        underLinedTitle={false}
                        coverImage={true}
                    />
                </div>
            </div>
            <div className="container mt-5">

                <div className="card-rounded mb-3">
                    <div className="row">
                        <div className="col-lg-1half col-xl-1 text-center">
                            <i className="fas fa-receipt fa-fw text-primary" style={{ fontSize: "60px" }} />
                            <br /><br />
                        </div>
                        <div className="col-lg-10half col-xl-11">
                            <h4 className="card-title">Lagere energierekening</h4>
                            <p className="card-text">Belangrijkste voor u is natuurlijk dat de energierekening
                                omlaag gaat. Met zonnepanelen bent u niet meer
                                afhankelijk van uw energieleverancier en heeft u geen last van de stijgende
                                energieprijzen. Wordt er meer
                                zonne-energie opgewekt dan u verbruikt? Deze stroom wordt verkocht aan uw
                                energieleverancier wat zorgt dat uw energierekening nòg lager wordt.</p>
                        </div>
                    </div>
                </div>

                <div className="card-rounded mb-3">
                    <div className="row">
                        <div className="col-lg-1half col-xl-1 text-center">
                            <i className="fab fa-pagelines fa-fw text-primary" style={{ fontSize: "60px" }} />
                            <br /><br />
                        </div>
                        <div className="col-lg-10half col-xl-11">
                            <h4 className="card-title">Goed voor het Millieu</h4>
                            <p className="card-text">Zonne-energie is schoon en duurzaam. De regering wil dat
                                Nederland meer gebruik gaat maken van duurzame
                                groene stroom. Zonne-energie is hier de perfecte manier voor. Tevens zorgen
                                zonnepanelen voor een
                                vermindering van de CO<sub>2</sub> uitstoot.</p>
                        </div>
                    </div>
                </div>

                <div className="card-rounded mb-3">
                    <div className="row">
                        <div className="col-lg-1half col-xl-1 text-center">
                            <i className="fas fa-home fa-fw text-primary" style={{ fontSize: "60px" }} />
                            <br /><br />
                        </div>
                        <div className="col-lg-10half col-xl-11">
                            <h4 className="card-title">Verhoog de waarde van uw huis</h4>
                            <p className="card-text">Zonnepanelen verhogen de waarde van een huis net zoals
                                spouwmuurisolatie, zuinige CV ketel en dubbel glas omdat
                                je energielabel van je huis stijgt. Tevens zorgen zonnepanelen voor lagere
                                maandlasten, wat ook weer
                                interressant is voor de potentiele eigenaar.</p>
                        </div>
                    </div>
                </div>

                <div className="card-rounded mb-3">
                    <div className="row">
                        <div className="col-lg-1half col-xl-1 text-center">
                            <i className="fas fa-money-bill fa-fw text-primary" style={{ fontSize: "60px" }} />
                            <br /><br />
                        </div>
                        <div className="col-lg-10half col-xl-11">
                            <h4 className="card-title">Rendement van zonnepanelen</h4>
                            <p className="card-text">De terugverdientijd van installaties die wij
                                installeren is meestal 4-6 jaar. De levensduur van
                                zonnepanelen ligt tussen de 30 – 40 jaar. Bij een installatie van 10
                                zonnepanelen heeft u na 25 jaar een
                                rendement van 25.000 euro.</p>
                        </div>
                    </div>
                </div>

            </div>

            <CallToAction />
        </React.Fragment>
    )
}

export default ZonnepanelenVoordelen;
