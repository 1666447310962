import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LeadinfoTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const enabledRoutes = ["/offerte", "/thuisbatterij", "/zonnepanelen", "/laadpalen", "/producten", "/ems"];
    const isEnabledRoute = enabledRoutes.some(route => location.pathname.includes(route));
    
    if (isEnabledRoute) {
        (function(l, e, a, d, i, n, f, o) {
            if (!l[i]) {
                l.GlobalLeadinfoNamespace = l.GlobalLeadinfoNamespace || [];
                l.GlobalLeadinfoNamespace.push(i);
                l[i] = function() {
                    (l[i].q = l[i].q || []).push(arguments);
                };
                l[i].t = l[i].t || n;
                l[i].q = l[i].q || [];
                o = e.createElement(a);
                f = e.getElementsByTagName(a)[0];
                o.async = 1;
                o.src = d;
                f.parentNode.insertBefore(o, f);
            }
        }(window, document, 'script', 'https://cdn.leadinfo.net/ping.js', 'leadinfo', 'LI-671629C5EC941'));
      }
  }, [location.pathname]);

  return null;
};

export default LeadinfoTracker;
