import React from "react";
import OfferteWidget from "./OfferteWidget";

import banner from "../img/solar-banner.jpg";
const HomepageBanner = ({ image = banner, imgAlt = "", title, subtitle, offerteWidget, offerteType }) => {
    return (
        <div className="home-banner">
            <img src={image} alt={imgAlt} />
            <div className="overlay">
                <div className="content-container">
                    <div className="title-container">
                        <h1 className="title"> {title}</h1>
                        <p className="subtitle">{subtitle}</p>
                    </div>
                    {offerteWidget && (
                        <div className="offerte-widget">
                            <OfferteWidget predefinedType={offerteType} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default React.memo(HomepageBanner);
