import React from "react";
import {
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import Helmet from "../../components/Helmet";
import BTWTerugvraagForm from "./BTWTerugvraagForm";
import Loading from "../../components/Loading";
import axios from "axios";
import NavbarSettings from "../../components/navbar/NavbarSettings";
import SubPageBanner from "../../components/SubPageBanner";

class BTWTerugvraagProcess extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tokenChecked: false,
            loading: false,
            error: null,
            errorSave: null,

            initials: "",
            lastName: "",
            birthday: "",

            phoneNumber: "",
            email: "",

            street: "",
            houseNumber: "",
            postalCode: "",
            city: "",

            entrepreneur: null,
            bsn: "",
            iban: "",
            ibanName: "",

            wp: "",

            fileIdentification1: null,
            fileIdentification2: null,
            fileBankCard: null,

            acceptCustomer: false,
            acceptPolicies: false
        }
        this.recaptchaRef = React.createRef();
        this.onRecaptchaVerify = this.onRecaptchaVerify.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    componentDidMount() {
        this.checkToken();
    }

    checkToken() {
        const token = this.props.match.params.token;
        if(!token) {
            this.setState({ error: "Deze link voor de BTW Terugvraag is niet geldig." });
            return;
        }
        axios.post("/checkBTWTerugvraagToken", { token })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ tokenChecked: true });
                } else {
                    this.setState({ error: response.data.error });
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                window.scrollTo({ top: 0, behavior: "smooth" });
            });
    }

    onFormSubmit(event) {
        event.preventDefault();
        this.setState({ errorSave: null, loading: true });
        this.recaptchaRef.current.reset();
        this.recaptchaRef.current.execute();
    }

    onRecaptchaVerify(recaptchaData) {
        console.log(recaptchaData);
        if(recaptchaData !== null) {
            this.onSubmit(recaptchaData);
        } else {
            this.setState({ loading: false });
        }
    }

    onSubmit(recaptchaData) {
        const token = this.props.match.params.token;
        if(!token) {
            this.setState({ errorSave: "Deze link voor de BTW Terugvraag is niet geldig." });
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }
        if(!this.state.acceptCustomer || !this.state.acceptPolicies) {
            this.setState({ errorSave: "Akkoord gaan met de voorwaarden is vereist." });
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }
        const { fileIdentification1, fileIdentification2, fileBankCard } = this.state;
        if(fileIdentification1 === null || fileBankCard === null) {
            if(fileIdentification1 === null && fileBankCard !== null) {
                this.setState({ errorSave: "Foto van de voorkant (en achterkant) van uw paspoort/rijbewijs/ID-kaart mist." });
            } else if(fileIdentification1 !== null && fileBankCard === null) {
                this.setState({ errorSave: "Foto van uw bankpas/bankafschrift mist." });
            }
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }

        this.setState({ errorSave: null, loading: true });

        const postFields = {
            recaptchaData,
            token,
            initials: this.state.initials,
            lastName: this.state.lastName,
            birthday: this.state.birthday,
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
            street: this.state.street,
            houseNumber: this.state.houseNumber,
            postalCode: this.state.postalCode,
            city: this.state.city,
            entrepreneur: this.state.entrepreneur,
            bsn: this.state.bsn,
            iban: this.state.iban,
            ibanName: this.state.ibanName,
            wp: this.state.wp
        };

        const formData = new FormData();
        Object.entries(postFields).forEach(([key, value]) => {
            formData.append(key, value);
        });

        formData.append("identification1", fileIdentification1, fileIdentification1.name);
        if(fileIdentification2 !== null) {
            formData.append("identification2", fileIdentification2, fileIdentification2.name);
        }
        formData.append("bankCard", fileBankCard, fileBankCard.name);

        axios.post("/submitBTWTerugvraag", formData, {
            transformRequest: (data, headers) => {
                // eslint-disable-next-line prefer-reflect
                delete headers.post["Content-Type"];
                return data;
            }
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.history.push("/btwterugvraag/terugvraag/bedankt/");
                } else {
                    this.setState({ loading: false, errorSave: response.data.error });
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loading: false, errorSave: "Er ging iets fout. Probeer het later opnieuw." });
                window.scrollTo({ top: 0, behavior: "smooth" });
            })
    }

    onChange(state) {
        this.setState({ ...state });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet title="BTW Terugvraag Formulier"/>

                <NavbarSettings>
                    <SubPageBanner
                        title="BTW Terugvraag"
                    />
                </NavbarSettings>

                <div className="container my-5">
                    { this.state.error ? (
                        <Alert variant="danger">{ this.state.error }</Alert>
                    ) : !this.state.tokenChecked ? (
                        <Loading/>
                    ) : (
                        <React.Fragment>
                            { this.state.errorSave && (
                                <Alert variant="danger">{ this.state.errorSave }</Alert>
                            )}
                            <BTWTerugvraagForm
                                fields={ this.state }
                                onChange={ this.onChange }
                                onSubmit={ this.onFormSubmit }
                                loading={ this.state.loading }
                                recaptchaRef={ this.recaptchaRef }
                                onRecaptchaVerify={ this.onRecaptchaVerify }
                            />
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(BTWTerugvraagProcess);
