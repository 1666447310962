import React from "react";
import {
    Link
} from "react-router-dom";
import NavbarSettings from "../../components/navbar/NavbarSettings";
import SubpageBanner from "../../components/SubPageBanner";

import Helmet from "../../components/Helmet";
import CallToAction from "../../components/CallToAction";

function ProductEnphase() {
    return (
        <React.Fragment>
            <Helmet
                title="Enphase"
                description="Verhoog het rendement van jouw zonnepanelen met Enphase micro-omvormers. Ontvang een gratis offerte en maak de keuze vandaag nog!"
            />
            <NavbarSettings>
                <SubpageBanner
                    title="Enphase"
                />
            </NavbarSettings>
            
            <div className="container container-sm mt-5">

                <div className="article-open">
                    <p>
                        Bij Smith Solar zijn we toegewijd aan het leveren van geavanceerde zonne-energie-oplossingen die
                        jouw rendement maximaliseren. We zijn dan ook trots om Enphase op te nemen in ons assortiment.
                        Enphase biedt een innovatieve micro omvormer die de traditionele werking van zonnepanelen
                        verbetert. Wil je het maximale rendement uit je zonnepanelen halen en tegelijkertijd
                        schaduwproblemen minimaliseren? Dan is Enphase dé oplossing voor jouw zonnepaneleninstallatie.
                    </p>
                </div>

                <h2>
                    Wat zijn Enphase micro omvormers?
                </h2>
                <p>
                    Enphase micro omvormer wordt rechtstreeks onder elk zonnepaneel gemonteerd. In tegenstelling tot
                    conventionele omvormers die één grote centrale omvormer gebruiken, heeft <b>elk paneel zijn eigen micro
                    omvormer</b>. Hierdoor wordt schaduwwerking per paneel tegengegaan, wat resulteert in een aanzienlijke
                    verbetering van de systeem efficiëntie. Zelfs vogelpoep of kleine vervuilingen kunnen een grote
                    impact hebben op de opbrengst bij conventionele systemen, terwijl Enphase dit probleem effectief
                    oplost.
                </p>

                <h2>
                    Verhoog je rendement met Enphase micro omvormer
                </h2>
                <p>
                    Traditionele zonnepanelen installaties kunnen worden beïnvloed door kleine verschillen tussen de
                    panelen, wat leidt tot een lagere totale opbrengst. Met Enphase wordt <b>elk paneel individueel
                    geoptimaliseerd</b>, waardoor alle panelen de prestaties van het best presterende paneel volgen. Dit
                    maximaliseert het potentieel van elk zonnepaneel en resulteert in een hogere energieopbrengst.
                </p>

                <h2>
                    Meer voordelen van Enphase
                </h2>
                <p>
                    Met Enphase en Smith Solar kies je voor een efficiënte, betrouwbare en toekomstgerichte
                    zonne-energie-oplossing. Ontdek de voordelen van Enphase en maak vandaag nog de overstap naar
                    duurzame energie.
                </p>

                <h3>
                    Geavanceerd monitoringssysteem
                </h3>
                <p>
                    Bij Smith Solar willen we dat onze klanten de volledige controle hebben over hun
                    zonne-energiesysteem. Daarom biedt Enphase het <b>Enlighten monitoringplatform</b>, toegankelijk via de
                    handige app op je telefoon. Met Enlighten kun je elk paneel afzonderlijk monitoren en krijg je
                    gedetailleerde inzichten in de prestaties van je zonnepanelen. Zo kun je nauwkeurig zien welke
                    panelen optimaal functioneren en eventuele problemen snel opsporen. Met deze waardevolle informatie
                    blijf je de baas over je energieproductie!
                </p>

                <h3>
                    Geschikt voor kleine en grote installaties
                </h3>
                <p>
                    Enphase micro-omvormers zijn <b>geschikt voor een breed scala aan installaties</b>. Of je nu een kleine
                    residentiële installatie hebt met slechts een paar panelen of een uitgebreide commerciële
                    installatie met veel panelen, Enphase past zich moeiteloos aan jouw behoeften aan. Onze deskundige
                    installateurs bij Smith Solar staan klaar om je te adviseren over de beste Enphase-configuratie voor
                    jouw situatie.
                </p>

                <h3>
                    Ondersteuning voor de Enphase AC gekoppelde thuisbatterij
                </h3>
                <p>
                    Duurzame energieopslag wordt steeds belangrijker, en Enphase begrijpt dit. Daarom biedt Enphase
                    <b>naadloze integratie met de Enphase AC gekoppelde thuisbatterijen</b>. Deze batterijen maken gebruik van
                    dezelfde AC-technologie als de micro-omvormers, wat zorgt voor een efficiëntere opslag van de
                    geproduceerde energie. Met de Enphase thuisbatterijen kun je overtollige energie opslaan en
                    gebruiken wanneer je het nodig hebt, waardoor je zelfvoorzienend wordt en minder afhankelijk van het
                    elektriciteitsnet.
                </p>

                <h3>
                    Lange garantieperiode
                </h3>
                <p>
                    Bij het investeren in zonne-energie is het belangrijk om te weten dat je kunt vertrouwen op de
                    kwaliteit van de gebruikte producten. Met Enphase ben je gedekt door een <b>uitgebreide garantie van 25
                    jaar op de Enphase micro omvormer</b>. Zo geniet je van gemoedsrust en een duurzaam systeem voor de
                    lange termijn.
                </p>

                <h2>
                    Contact
                </h2>
                <p>
                    Heb je nog vragen over Enphase micro omvormer of wil je meer weten over onze
                    zonne-energieoplossingen? Aarzel niet om <Link to="/contact/">contact</Link> op
                    te nemen met ons team van experts. We staan klaar
                    om al jouw vragen te beantwoorden en jou te helpen bij het kiezen van de beste
                    zonne-energieoplossing voor jouw specifieke behoeften.
                </p>

                <h2>
                    Offerte aanvragen voor Enphase
                </h2>
                <p>
                    Ben je klaar om het maximale rendement uit je zonnepanelen te halen met Enphase micro omvormer?
                    Smith Solar staat voor je klaar om je te helpen bij het realiseren van jouw zonne-energie dromen.
                    Vraag vandaag nog een <Link to="/offerte/">offerte</Link> aan voor jouw Enphase-systeem. Ons team van
                    deskundige installateurs staat klaar om jou te begeleiden bij elke stap van het proces, van advies
                    tot installatie.
                </p>

                <CallToAction
                    title="Waar wacht je nog op?"
                    subtitle="Maak gebruik van de nieuwste technologie en verhoog je zonne-energieproductie met Enphase!"
                />

            </div>
        </React.Fragment>
    );
}

export default React.memo(ProductEnphase);
