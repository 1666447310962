/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useState, useRef, useMemo, useCallback } from "react";
import { useRouteMatch, withRouter } from "react-router-dom";
import moment from "moment";
import SubPageBanner from "../components/SubPageBanner";
import banner from "../img/solar-banner.jpg";

const ThemeContext = createContext(null);

function DefaultPageBanner() {
    return <SubPageBanner title="Smith Solar B.V." subtitle="Specialist in zonne-energie" image={banner} imgAlt="Foto van zonnepanelen" />;
}

function ThemeManagerInternal({ location, children }) {
    const [currentNavbarHeight, setCurrentNavbarHeight] = useState(null);
    const [pageBanner, setPageBannerInternal] = useState(<DefaultPageBanner />);
    const pageUrlRef = useRef(null);
    const date = moment().format("YYYY-MM-DD");
    const year = moment().format("YYYY");
    const blackFriday = (moment(date) >= moment("2023-11-12") && moment(date) <= moment("2023-11-25")) || location.search === "?blackfriday";
    const christmas = (moment(date) > moment(year + "-12-05") && moment(date) < moment(year + "-12-27")) || location.search === "?christmas";

    const homeMatch = useRouteMatch({
        path: [
            "/",
            "/campagne/boelhuis",
            "/campagne/gazetta",
            "/campagne/helmheus",
            "/campagne/mobile",
            "/campagne/mobile-gasprijzen",
            "/campagne/mobile-actie",
        ],
        exact: true,
    });
    const darkNavbar = blackFriday && homeMatch !== null;
    const hideLinks =
        useRouteMatch({
            path: ["/btwterugvraag/terugvraag", "/btwterugvraag/terugvraag/:token", "/offerte/deur-tot-deur", "/offerte/deur-tot-deur/bedankt/:type"],
            exact: true,
        }) !== null;

    const [context, setContext] = useState({
        darkNavbar,
        blackFriday,
        christmas,
        hideLinks,
    });

    useEffect(() => {
        setContext((prevContext) => {
            return { ...prevContext, darkNavbar, hideLinks };
        });
    }, [location, darkNavbar, hideLinks]);

    const setPageBanner = useCallback((url, banner) => {
        setPageBannerInternal(banner);
        pageUrlRef.current = url;
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setPageBannerInternal((prevBanner) => {
                if (pageUrlRef.current === location.pathname) {
                    return prevBanner;
                }
                pageUrlRef.current = location.pathname;
                return <DefaultPageBanner />;
            });
        }, 100);
    }, [location, setPageBanner]);

    return (
        <ThemeContext.Provider
            value={{
                currentNavbarHeight,
                setCurrentNavbarHeight,
                pageBanner,
                setPageBanner,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
}
export const ThemeManager = withRouter(ThemeManagerInternal);

export function withThemeContext(Component) {
    return function contextComponent(props) {
        return <ThemeContext.Consumer>{(context) => <Component {...props} themeContext={context} />}</ThemeContext.Consumer>;
    };
}

export default ThemeContext;
