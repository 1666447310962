import React from "react";

import banner from "../img/solar-banner.jpg";
const SubPageBanner = ({ image = banner, imgAlt = "", title, subtitle, overlayClass = "sub-banner" }) => {
    return (
        <div className={overlayClass}>
            <img src={image} alt={imgAlt} />
            <div className="titleContainer">
                <h1 className="title"> {title}</h1>
                <p className="subtitle">{subtitle}</p>
            </div>
        </div>
    );
};

export default React.memo(SubPageBanner);
