import React from "react";
import Helmet from "../../components/Helmet";

import SwiperComponent from "../../components/SwiperComponent";
import ProductPlans from "../../components/ProductPlans";

import NavbarSettings from "../../components/navbar/NavbarSettings";
import HomepageBanner from "../../components/HomepageBanner";

import banner from "../../img/zonnepanelen/solar-zonnepanelen-schuindak.jpg";
import zonnepanelenImage from "../../img/zonnepanelen/solar-zonnepanelen-schuindak.jpg";
import zonnepanelenImage2 from "../../img/zonnepanelen/solar-zonnepanelen-platdak.jpg";
import zonnepanelenImage3 from "../../img/zonnepanelen/zonnepanelen-schuindak-4.jpg";
import zonnepanelenImage4 from "../../img/zonnepanelen/zonnepanelen-schuindak-5.jpg";
import zonnepanelenInstalleren from "../../img/installeren/installeren-zonnepaneel.jpg";
import Reviews from "../../components/Reviews";

function Producten() {
    const slides = [
        {
            id: 1,
            title: "Introductie tot Zonnepanelen",
            description: "Leer de basisprincipes van zonnepanelen en hoe ze werken.",
            image: zonnepanelenImage,
        },
        {
            id: 2,
            title: "Voordelen van Zonnepanelen",
            description: "Ontdek de vele voordelen van het installeren van zonnepanelen.",
            image: zonnepanelenImage2,
        },
        {
            id: 3,
            title: "Installatieproces",
            description: "Een stapsgewijze gids voor het installeren van zonnepanelen.",
            image: zonnepanelenInstalleren,
        },
        {
            id: 4,
            title: "Onderhoud en Zorg",
            description: "Hoe je je zonnepanelen in topconditie houdt.",
            image: zonnepanelenImage3,
        },
        {
            id: 5,
            title: "Kosten en Besparingen",
            description: "Een overzicht van de kosten en de besparingen die je kunt verwachten.",
            image: zonnepanelenImage4,
        },
    ];

    const swiperConfig = {
        slidesPerView: 3,
        spaceBetween: 50,
        pagination: { clickable: true },
        navigation: true,
        loop: false,
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
    };

    const plans = [
        {
            title: "6 zonnepanelen",
            price: "€2.700",
            saving: "€580",
            usps: [
                <p>
                    Terugverdiend in <strong>5.8 jaar</strong>
                </p>,
                <p>
                    Volledig geïnstalleerd
                </p>,
                <p>
                    Inclusief app
                </p>,
            ],
        },
        {
            title: "8 zonnepanelen",
            price: "€3.000",
            saving: "€770",
            usps: [
                <p>
                    Terugverdiend in <strong>4.8 jaar</strong>
                </p>,
                <p>
                    Volledig geïnstalleerd
                </p>,
                <p>
                    Inclusief app
                </p>,
            ],
        },
        {
            title: "10 zonnepanelen",
            price: "€3.400",
            saving: "€950",
            usps: [
                <p>
                    Terugverdiend in <strong>4.5 jaar</strong>
                </p>,
                <p>
                    Volledig geïnstalleerd
                </p>,
                <p>
                    Inclusief app
                </p>,
            ],
        },
        {
            title: "10 zonnepanelen",
            subtitle: "2 kanten v/d nok",
            price: "€4.100",
            saving: "€967",
            usps: [
                <p>
                    2 kanten van de nok
                </p>,
                <p>
                    Terugverdiend in <strong>5.2 jaar</strong>
                </p>,
                <p>
                    Inclusief app
                </p>,
                <p>
                    3 gratis optimizers
                </p>
            ],
        },
    ];

    const reviews = [
        {
            title: "Altijd goed geholpen",
            description: "Smith Solar B.V. heeft mij goed geholpen met de aanschaf en installatie van de zonnepanelen! Communicatie altijd vriendelijk en functioneel!",
            rating: 5,
            source: "Google",
            url: "https://maps.app.goo.gl/S5QZiKqdxNHrFJC19",
        },
        {
            title: "Optimale plaatsing",
            description: "Smith Solar heeft al onze PV systemen geinstalleerd en uitgebreid na snel en vakkundig overleg. De panelen zijn aangebracht met een optimale plaatsing zodat het maximale dak is benut.",
            rating: 5,
            source: "Google",
            url: "https://maps.app.goo.gl/GDzPVR2gWtK4Luui9",
        },
        {
            title: "Goede ervaring",
            description: "Geheel volgens afspraak geleverd en geïnstalleerd 5 extra zonnepanalen, had in 2017 ook al goede ervaring met Smith Solar.",
            rating: 4,
            source: "Google",
            url: "https://g.co/kgs/S5xSL2n",
        },
    ];
    return (
        <React.Fragment>
            <Helmet
                title="Over zonnepanelen"
                description="Zonnepanelen kopen doe je niet zomaar. Een zonnepaneel gaat meer dan 25 jaar mee. Dat wil je goed voorbereiden. Hier vind je alle informatie over zonnepanelen."
            />

            <NavbarSettings>
                <HomepageBanner 
                    title="Zonnepanelen"
                    subtitle="Overzicht"
                    image={banner}
                    imgAlt="Foto van zonnepanelen"
                    offerteWidget
                    offerteType="zonnepanelen"
                />
            </NavbarSettings>

            <div className="container">
                <SwiperComponent title="Zonnepanelen installeren in 5 stappen" slides={slides} config={swiperConfig} />
            </div>
            <div className="container">
                <ProductPlans
                    title="Onze verschillende pakketten"
                    description="Onderstaande berekeningen zijn afhankelijk van de energieprijs en opbrengst. Dit is dus enkel ter indicatie! Neem contact met ons op voor een meer nauwkeurigeberkening. Prijzen zijn exclusief BTW."
                    plans={plans}
                />
                <Reviews title="Reviews" reviews={reviews} />
            </div>
        </React.Fragment>
    );
}

export default Producten;
