import React from "react";
import { Link } from "react-router-dom";

import Helmet from "../components/Helmet";
import NavbarSettings from "../components/navbar/NavbarSettings";
import SubPageBanner from "../components/SubPageBanner";
import HomepageImageText from "../components/HomepageImageText";
import OverzichtCallToAction from "../components/OverzichtCallToAction";

import banner from "../img/solar-banner.jpg";
import se100k from "../img/producten/solaredge/se100k.png";
import zonnepanelenClose from "../img/zonnepanelen/zonnepanelen-schuindak-2.jpg";
import valkPitchedLogo from "../img/brands/vandervalksolarsystems.svg";
import flatFixFusionLogo from "../img/brands/flatfixfusion.svg";
import solarEdgeLogo from "../img/brands/solaredge.svg";
import enphaseLogo from "../img/brands/enphase.svg";
import apsystemsLogo from "../img/brands/apsystems.svg";
import aforeLogo from "../img/brands/afore.svg";
import omvormerImage from "../img/producten/solaredge/solaredge-3-phase-no-display.png";
import blockSeperator from "../img/svg/block-seperator.svg"

function Producten() {

    const omvormersData = [
        {
            title: "SolarEdge",
            text: "Voor degenen die op zoek zijn naar topprestaties en monitoring mogelijkheden, is de SolarEdge omvormer een uitstekende keuze. Deze omvormers optimaliseren de opbrengst van elk afzonderlijk zonnepaneel, wat resulteert in maximale efficiëntie.",
            link: "/producten/solaredge/",
            img: solarEdgeLogo,
            imgAlt: "Logo van SolarEdge"
        },
        {
            title: "Enphase",
            text: "Enphase micro omvormers zijn ideaal voor wie het beste uit elk zonnepaneel wil halen. Deze innovatieve micro-omvormers zorgen voor een betere betrouwbaarheid en opbrengst, zelfs onder variabele omstandigheden.",
            link: "/producten/enphase/",
            img: enphaseLogo,
            imgAlt: "Logo van enphase"
        },
        {
            title: "APsystems",
            text: "Voor wie op zoek is naar kostenefficiënte micro omvormers, bieden we de APsystems micro omvormer. Deze omvormers leveren consistente prestaties en betrouwbaarheid.",
            link: "/producten/apsystems/",
            img: apsystemsLogo,
            imgAlt: "Logo van apsystems"
        },
        {
            title: "Afore",
            text: "Een Afore omvormer staat bekend om zijn hoogwaardige kwaliteit en betrouwbare prestaties, en biedt een uitstekende prijs-kwaliteitverhouding. Of het nu gaat om een kleine residentiële installatie of een grootschalig commercieel zonne-energieproject, Afore omvormers zijn flexibel, compatibel met verschillende batterijen en bieden real-time monitoring via de Solarman app.",
            link: "/producten/afore/",
            img: aforeLogo,
            imgAlt: "Logo van afore"
        }
    ];

    return (
        <React.Fragment>
            <Helmet title="Producten" />

            <NavbarSettings>
                <SubPageBanner
                    title="Producten"
                    image={banner}
                    imgAlt="Foto van zonnepanelen"
                />
            </NavbarSettings>

            <div className="container-orange-background">
                <div className="container">
                    <h1 className="text-center"> Alles wat je wilt weten over zonnepanelen</h1>
                    <HomepageImageText
                        title="Dit is het moment."
                        text="Zonnepanelen kopen doe je niet zomaar. Een zonnepaneel gaat meer dan 25 jaar mee. Dat wil je goed voorbereiden. Hier vind je alle informatie over zonnepanelen.
                        De technologie van zonnepanelen is vernieuwd, de prijzen van zonnepanelen zijn gedaald, er is subsidie en je kunt zonnepanelen op meer manieren toepassen. Nu is het de tijd om zonnepanelen te kopen."
                        img={se100k}
                        imgAlt="Foto van zonnepanelen"
                        underLinedTitle={false}
                        coverImage={false}
                    />
                </div>
            </div>
            <div className="container-white-background">
                <div className="container">
                    <h2 className="text-center">Waar het allemaal begint</h2>
                    <HomepageImageText
                        title="Zonnepanelen"
                        titleTag="h3"
                        text={
                        <>
                            <p>
                                Ontdek de kracht van zonne-energie met Ulica Solar! Bij Smith Solar B.V. gaan we voor niets minder
                                dan topkwaliteit en dat is precies wat Ulica Solar zonnepanelen bieden. Deze Tier 1 zonnepanelen
                                stralen innovatie en betrouwbaarheid uit, waardoor ze een favoriet zijn bij onze klanten.
                            </p>
                            <p>Waarom keizen voor Ulica Solar zonnepanelen?</p>
                            <ul style={{ textAlign: 'left', listStyleType: 'none', padding: 0 }}>
                                <li className="d-flex align-items-center"> <i className="fa-solid fa-check mr-2 fa-lg" style={{color: "#00AA65"}}></i> Tier 1-geclassificeerde zonnepanelen</li>
                                <li className="d-flex align-items-center"> <i className="fa-solid fa-check mr-2 fa-lg" style={{color: "#00AA65"}}></i> Indrukwekkende opbrengstgarantie</li>
                                <li className="d-flex align-items-center"> <i className="fa-solid fa-check mr-2 fa-lg" style={{color: "#00AA65"}}></i> Royale fabrieksgarantie van 12 jaar</li>
                                <li className="d-flex align-items-center"> <i className="fa-solid fa-check mr-2 fa-lg" style={{color: "#00AA65"}}></i> Ulica Solar en Smith Solar werken al samen sinds 2013.</li>
                            </ul>
                        </>
                        }
                        img={zonnepanelenClose}
                        imgAlt="Foto van zonnepanelen"
                        coverImage={true}
                        underLinedTitle={false}
                        buttonLink={"/producten/ulica-solar/"}
                        buttonText="Meer over Ulica Solar"
                    />
                </div>
            </div>
            <div>
                <div className="container my-0 py-0">
                    <HomepageImageText
                            title="Omvormers"
                            titleTag="h3"
                            text="Een omvormer is een essentieel onderdeel van elk zonne-energiesysteem. Omvormers spelen een cruciale rol in het omzetten van de opgevangen zonne-energie in bruikbare stroom voor je huis of bedrijf. Daarom vind je bij Smith Solar een groot aanbod omvormers van topmerken als SolarEdge, Enphase, APsystems en Afore om ervoor te zorgen dat jouw zonne-energiesysteem op zijn best presteert."
                            img={omvormerImage}
                            imgAlt="Foto van een omvormer"
                            isReverse={true}
                            underLinedTitle={false}
                    />

                    {omvormersData.map((omvormer, index) => (
                        <div className="image-text-container my-0" key={index}>
                            <div className="text-container">
                                <h2 className="title">{omvormer.title}</h2>
                                <p className="text">{omvormer.text}</p>
                                <div>
                                    <Link to={omvormer.link} className="btn btn-primary mb-4">Meer over {omvormer.title}</Link>
                                </div>
                                </div>
                            <div className="image-container">
                                <img src={omvormer.img} alt={omvormer.imgAlt} className="contain p-5"/>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="w-full" style={{height: 200, position: "relative"}}>
                    <img src={blockSeperator} alt="Block seperator" style={{width: "100%", position: "absolute", objectFit: "cover", bottom: 0}}/>
                </div>
            </div>
            <div className="container-white-background py-5 mt-0">
                <h2 className="text-center">Bevestigingsmateriaal</h2>
                <div className="container">
                    <p>Het juiste bevestigingsmateriaal is cruciaal voor een goede werking van jouw zonne-energiesysteem. Bij Smith Solar hebben we een selectie bevestigingsmateriaal van topkwaliteit om ervoor te zorgen dat jouw zonnepanelen veilig en efficiënt worden geïnstalleerd. Of je nu een plat dak of een schuin dak hebt, we hebben de perfecte oplossingen voor jou.</p>
                    <div className="image-text-container" >
                        <div className="text-container">
                            <h3 className="title">Flat Fix Fusion</h3>
                            <p className="text">Flat Fix Fusion is een geavanceerd en lichtgewicht montagesysteem voor zonnepanelen met geïntegreerde winddeflectoren en modulaire flexibiliteit, waardoor het snel geïnstalleerd kan worden. Het systeem is uitermate geschikt als bevestigingsmateriaal zonnepanelen voor een plat dak en heeft een geïntegreerd kabelmanagement systeem. Flat Fix Fusion is verkrijgbaar in een aantrekkelijke zwarte uitvoering en wordt geleverd met een indrukwekkende garantie van 20 jaar.</p>
                            <div>
                                <Link to="/producten/flat-fix-fusion/" className="btn btn-primary mb-4">Meer over Flat Fix Fusion</Link>
                            </div>
                        </div>
                        <div className="image-container">
                            <img src={flatFixFusionLogo} alt="Logo van Flat Fix Fusion" className="contain"/>
                        </div>
                    </div>
                    <div className="image-text-container" >
                        <div className="text-container">
                            <h3 className="title">ValkPitched</h3>
                            <p className="text">Ben je op zoek naar bevestigingsmateriaal voor zonnepanelen voor een schuin dak, dan is ValkPitched een uitstekende keuze. Dit systeem is speciaal ontwikkeld om zonnepanelen op schuine daken te bevestigen en biedt duurzame prestaties en betrouwbaarheid. Met Valkpitched ben je verzekerd van een veilige installatie die jarenlang meegaat.</p>
                            <div>
                                <Link to="/producten/valkpitched/" className="btn btn-primary">Meer over ValkPitched</Link>
                            </div>
                        </div>
                        <div className="image-container">
                            <img src={valkPitchedLogo} alt="Logo van van der valk solar systems" className="contain"/>
                        </div>
                    </div>
                </div>
            </div>

            <OverzichtCallToAction
                title="Vragen over welk materiaal het beste bij u gebruikt kan worden?"
                subtitle="Neem contact met ons op en wij helpen u graag verder."
                buttonLink="/contact/"
                buttonText="Neem contact op"
            />

           
            <div className="container text-muted mt-4">
                <p>* Opbrengstgarantie: Garantie van opbrengst vergeleken met moment van in bedrijfneming als percentage na X jaar.</p>
            </div>
        </React.Fragment>
    );
}

export default Producten;
