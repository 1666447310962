import React from "react";

import { Link } from "react-router-dom";
import Helmet from "../components/Helmet";
import CallToAction from "../components/CallToAction";
import NavbarSettings from "../components/navbar/NavbarSettings";
import SubPageBanner from "../components/SubPageBanner";
import HomepageImageText from "../components/HomepageImageText";

import banner from "../img/solar-banner.jpg";
import daelInstallatie from "../img/zonnepanelen/deal-zonnepanelen-installatie.jpg";
import se100k from "../img/producten/solaredge/se100k.png"

function Subsidie() {
    return (
        <React.Fragment>
            <Helmet
                title="Subsidie"
                description="Met SDE++ subsidie steunt de overheid projecten die bijdragen aan de verlaging van de CO2-uitstoot. Daaronder vallen ook zonnepanelen (ook wel zon-pv genoemd). Maar wat vergoedt de subsidie-regeling precies en wanneer komt u in aanmerking? Hieronder staat alles wat u moet weten over de SDE++ subsidie."
            />

            <NavbarSettings>
                <SubPageBanner
                    title="Zakelijke SDE Subsidie"
                    subtitle="Wat u moet weten"
                    image={daelInstallatie}
                    imgAlt="Foto van zonnepanelen"
                />
            </NavbarSettings>

            <div className="container-orange-background">
                <div className="container">
                    <HomepageImageText
                        title="Zakelijke SDE++ Subsidie"
                        text="Met SDE++ subsidie steunt de overheid projecten die bijdragen aan de verlaging van de CO2-uitstoot.
                        Daaronder vallen ook zonnepanelen (ook wel zon-pv genoemd). Maar wat vergoedt de subsidie-regeling
                        precies en wanneer komt u in aanmerking? Hieronder staat alles wat u moet weten over de SDE++
                        subsidie."
                        img={banner}
                        imgAlt="Foto van zonnepanelen"
                        coverImage
                    />
                </div>
            </div>

            <div className="container mt-4 py-4">
                <h2>1. Wat is de SDE++ subsidieregeling?</h2>
                <p>
                    De <strong>SDE++ regeling</strong> (Stimulering Duurzame Energieproductie en Klimaattransitie) stimuleert sinds 2008 projecten voor duurzame energieproductie. De regeling is in 2020 verbreed en richt zich nu ook op het verminderen van CO2-uitstoot. Voorbeelden hiervan zijn zonnepanelen op bedrijfsdaken of overdekte parkeerterreinen.
                </p>

                <h2>2. Kan ik de SDE++ subsidie het hele jaar door aanvragen?</h2>
                <p>
                    Nee, de <strong>SDE++</strong> subsidie kent 1 of 2 rondes per jaar. De overheid stelt per ronde 5 miljard euro beschikbaar. Timing is cruciaal, omdat de <strong>RVO</strong> de aanvragen per fase beoordeelt, waarbij het subsidiebedrag per fase stijgt. Hoe minder subsidie u aanvraagt per ton <strong>CO2-reductie</strong>, hoe groter de kans op toekenning. Smith Solar kan u adviseren over de beste aanvraagstrategie.
                </p>
                <p>
                    Als u dit jaar geen SDE++ subsidie toegekend krijgt, dan kunt u in de volgende ronde opnieuw
                    subsidie aanvragen. Smith Solar kan u adviseren over de werking van de SDE++.
                </p>

                <h2>3. Hoe werkt de SDE++ subsidie?</h2>

                <p>
                De SDE++ vergoedt het verschil tussen de kosten van duurzame energie en de opbrengsten. De subsidie wordt toegekend voor een periode van <strong>15 jaar</strong>. Het bedrag dat u ontvangt hangt af van de gerealiseerde <strong>CO2-reductie</strong> en het moment van aanvraag. De aanvraag is verdeeld in vier fases, waarbij het subsidiebedrag per fase hoger wordt. Het is verstandig om een strategie uit te denken voordat u de aanvraag indient. Smith Solar ondersteunt u bij elke stap.
                </p>

                <h2>4. Wanneer kom ik in aanmerking voor de SDE++ subsidie?</h2>
                <div className="image-text-container my-0">
                    <div className="text-container">
                        <div className="text">
                            <p>
                                Om de SDE++ subsidie aan te vragen, moet uw bedrijf of instelling aan een paar voorwaarden voldoen:
                            </p>
                            <ul>
                                <li>
                                    U heeft of laat een grootverbruiksaansluiting aanleggen (groter dan 3 x 80 Ampère).
                                </li>
                                <li>
                                    U installeert ten minste 15 kWp aan zonnepanelen (ongeveer 36 panelen, afhankelijk van het aantal Wp per paneel).
                                </li>
                            </ul>
                            <p>
                            De SE100K omvormer hiernaast is ideaal voor grote zakelijke zonne-installaties. Met een vermogen van 100 kW biedt hij de efficiëntie en betrouwbaarheid die nodig is voor projecten met een hoog rendement op energieproductie.
                            </p>

                            <p>
                            Voor meer informatie, neem contact op met Smith Solar. Wij helpen u graag verder.
                            </p>
                            <Link to="/contact" className="btn btn-primary">Neem contact op</Link>
                        </div>
                    </div>
                    <div className="image-container">
                        <img src={se100k} alt="SolarEdge SE100K omvormer" className="contain"/>
                    </div>
                </div>
            </div>

            <CallToAction />

        </React.Fragment>
    );
}

export default Subsidie;
