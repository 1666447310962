import check from "../img/zonnepanelen/check.svg";

const ProductPlans = ({ title, description, plans }) => {
    return (
        <div className="plans-container">
            <div className="plans-header">
                <h2 className="title"> {title} </h2>
                <p className="description"> {description} </p>
            </div>
            <div className="plans">
                {plans.map((plan, index) => (
                    <Plans key={index} plan={plan} />
                ))}
            </div>
        </div>
    );
};

const Plans = ({ plan }) => {
    return (
        <div className="plan-item">
            <div className="content">
                <h3 className="title">{plan.title}</h3>
                <p className="price"><span style={{fontSize: "1.5rem"}}>v/a </span>{plan.price}</p>
                <p className="savings">Besparing: {plan.saving} p/j*</p>
            </div>
            <ul className="usps">
                {plan.usps.map((usp, index) => (
                    <li key={index}>
                        <img src={check} className="check-icon" alt="Check icon" /> {usp}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ProductPlans;
