import React from "react";

import Helmet from "../../components/Helmet";
import NavbarSettings from "../../components/navbar/NavbarSettings";
import SubPageBanner from "../../components/SubPageBanner";

function BTWTerugvraagAlgemeneVoorwaarden() {
    return (
        <React.Fragment>
            <Helmet title="Privacyverklaring - BTW Terugvraag"/>

            <NavbarSettings>
                <SubPageBanner
                    title="Algemene voorwaarden"
                    subtitle="BTW Terugvraag"
                />
            </NavbarSettings>

            <div className="container my-5">
                <p><b>Deze algemene voorwaarden gelden alleen voor onze BTW Terugvraag service.</b></p>
                <p>Algemene Voorwaarden <span className="b-solar">Smith Solar B.V.</span>, gevestigd te (2676 LE)
                    Maasdijk aan Aartsdijkweg 45 en ingeschreven bij de Kamer van Koophandel te 's-Gravenhage
                    onder KvK-nummer 60218452.</p>

                <h2>Artikel 1 Toepasselijkheid</h2>
                <p>1.1 Deze voorwaarden gelden voor iedere aanbieding en iedere overeenkomst tussen <span
                    className="b-solar">Smith Solar B.V.</span> (hierna: opdrachtnemer), en een opdrachtgever, voor
                    zover van deze voorwaarden niet door partijen schriftelijk en uitdrukkelijk is afgeweken.</p>

                <p>1.2 Van het in deze algemene voorwaarden bepaalde kan uitsluitend en alleen worden afgeweken indien
                    en voor zover zulks uitdrukkelijk schriftelijk is overeengekomen.</p>

                <p>1.3 Indien één of meerdere bepalingen in deze algemene voorwaarden op enig moment geheel of
                    gedeeltelijk nietig zijn of vernietigd worden, dan blijven de overige bepalingen van toepassing.</p>

                <h2>Artikel 2 Totstandkoming overeenkomst</h2>
                <p>2.1 Een overeenkomst van opdracht komt eerst tot stand nadat de opdracht door opdrachtnemer
                    schriftelijk is aanvaard of wanneer de overeenkomst door de opdrachtgever en de opdrachtnemer is
                    ondertekend. Elektronische overeenkomsten komen tot stand doordat de opdrachtnemer de opdracht
                    schriftelijk aanvaardt middels een opdrachtbevestiging.</p>

                <p>2.2 De door opdrachtnemer aan de opdrachtgever verzonden opdrachtbevestiging wordt geacht de inhoud
                    van de gesloten overeenkomst volledig en juist weer te geven. De opdrachtgever wordt geacht met de
                    inhoud van de opdrachtbevestiging in te stemmen.</p>

                <h2>Artikel 3 Uitvoering overeenkomst</h2>
                <p>3.1 De opdrachtgever draagt er zorg voor dat alle gegevens, waarvan de opdrachtnemer aangeeft dat
                    deze noodzakelijk zijn of waarvan de opdrachtgever redelijkerwijs behoort te begrijpen dat deze
                    noodzakelijk zijn voor het voeren van de overeenkomst, tijdig aan de opdrachtnemer worden verstrekt.
                    Indien de voor de uitvoering van de overeenkomst benodigde gegevens niet tijdig aan de opdrachtnemer
                    zijn verstrekt, heeft opdrachtnemer het recht de uitvoering van de overeenkomst op te schorten.
                    Opdrachtnemer is verder niet aansprakelijk voor schade, van welke aard dan ook, ontstaan doordat
                    opdrachtnemer is uitgegaan van door de opdrachtgever verstrekte onjuiste en/of onvolledige gegevens,
                    tenzij deze onjuistheid of onvolledigheid voor haar kenbaar behoorde te zijn.</p>

                <p>3.2 De opdrachtnemer is te allen tijde bevoegd de overeenkomst voortijdig te beëindigen, indien, naar
                    het oordeel van de opdrachtnemer, blijkt van feiten of omstandigheden, die ten gevolge hebben dat
                    van de opdrachtnemer redelijkerwijze niet kan worden gevergd de opdracht te voltooien. In dat geval
                    heeft de opdrachtnemer recht op het afgesproken bedrag vermeldt in de overeenkomst.</p>

                <p>3.3 De opdrachtnemer draagt zorg voor de btw-teruggave van de zaak op basis van de door de
                    opdrachtgever verstrekte gegevens. Uitsluitend de externe partij, zoals de Belastingdienst bepaalt
                    of de opdrachtgever in aanmerking komt voor de belastingteruggaaf.</p>

                <p>3.4 De opdrachtgever geeft door het sluiten van de overeenkomst de opdrachtnemer de bevoegdheid al
                    hetgeen te doen om de btw-teruggave te kunnen indienen bij de Belastingdienst. De opdrachtnemer
                    meldt de opdrachtgever als ondernemer aan bij de Belastingdienst. Opdrachtnemer verzorgt in het
                    kader van de btw-teruggave van de zaak de kwartaalaangifte voor de opdrachtgever. Dit kunnen
                    meerdere aangiftes zijn, afhankelijk van het moment van aanmelding van de opdrachtgever bij de
                    Belastingdienst.</p>

                <p>3.5 Indien schriftelijk overeengekomen en noodzakelijk voor het aanvragen van de btw-teruggave van de
                    zaak vraagt de opdrachtnemer namens de opdrachtgever ontheffing van administratieve verplichtingen
                    aan. Indien de ontheffing niet wordt afgegeven door de instantie, dan kan de opdracht niet worden
                    voltooid en eindigt de overeenkomst. In dat geval heeft de opdrachtnemer recht op het afgesproken
                    bedrag vermeldt in de overeenkomst.</p>

                <p>3.6 Indien de btw-teruggave aan de opdrachtgever wordt toegekend treedt de opdrachtgever zelf in
                    contact over alle zaken betreffende de belastingteruggaaf.</p>

                <h2>Artikel 4 Prijs en betalingstermijn</h2>
                <p>4.1 De prijzen van opdrachtnemer zijn, tenzij uitdrukkelijk schriftelijk anders is overeengekomen, in
                    Euro’s, exclusief omzetbelasting en andere van overheidswege opgelegde heffingen.</p>

                <p>4.2 Betaling dient te geschieden in Euro’s, zonder aftrek van enige korting door overschrijving op
                    een door ons aangewezen bankrekening, in beide gevallen uiterlijk 7 dagen na de factuurdatum; dit
                    alles tenzij uitdrukkelijk schriftelijk anders is overeengekomen. De factuur wordt verzonden na het
                    sluiten van de overeenkomst.</p>

                <p>4.3 Indien de opdrachtgever niet tijdig tot (algehele) betaling overgaat, is de opdrachtgever in
                    verzuim zonder dat daarvoor een nadere ingebrekestelling is vereist en zijn alle vorderingen van ons
                    op de opdrachtgever, zonder dat ingebrekestelling vereist is, onmiddellijk opeisbaar.</p>

                <p>4.4 Indien de opdrachtgever niet tijdig tot (algehele) betaling overgaat, is de opdrachtnemer
                    gerechtigd om de werkzaamheden op te schorten.</p>

                <p>4.5 De opdrachtnemer vordert de btw op de aan de opdrachtgever verzonden factuur terug. Deze
                    btw-teruggave komt toe aan de opdrachtnemer.</p>

                <h2>Artikel 5 Aansprakelijkheid</h2>
                <p>5.1 De opdrachtnemer zal haar werkzaamheden naar eisen van goed vakmanschap verrichten. De
                    opdrachtnemer kan niet aansprakelijk gesteld worden indien de Belastingdienst niet overgaat tot
                    btw-teruggave.</p>

                <p>5.2 De opdrachtnemer is nimmer gehouden tot betaling van enigerlei schadevergoeding aan opdrachtgever
                    en aan anderen, tenzij sprake is van opzet of grove schuld van de kant van de opdrachtnemer.</p>

                <p>5.3 De opdrachtgever is gehouden de opdrachtnemer te vrijwaren voor alle aanspraken die derden ter
                    zake van de uitvoering van de overeenkomst tegen de opdrachtnemer mochten doen gelden. Tevens stelt
                    de opdrachtgever de opdrachtnemer schadeloos voor alle kosten en schade die uit eventuele
                    rechtsvorderingen van derden voor de opdrachtnemer kunnen ontstaan, zulks voor zover de wet zich er
                    niet tegen verzet dat de desbetreffende schade en kosten voor rekening van de opdrachtgever
                    komen.</p>

                <h2>Artikel 6 Overmacht</h2>
                <p>Indien de opdrachtnemer door overmacht de overeenkomst niet (tijdig) kunnen nakomen, heeft de
                    opdrachtnemer het recht de overeenkomst op een later tijdstip uit te voeren, dan wel de overeenkomst
                    als ontbonden te beschouwen. Deze keuze is aan de opdrachtnemer. In geval van overmacht is elke
                    schadeplichtigheid van de opdrachtnemer uit welke hoofde dan ook uitgesloten.</p>

                <h2>Artikel 7 Toepasselijk recht en forumkeuze</h2>
                <p>Op alle, door opdrachtnemer gesloten en te sluiten overeenkomsten, is uitsluitend Nederlands recht
                    van toepassing. Alle geschillen tussen partijen voortvloeiende uit deze overeenkomst of daarmee
                    rechtstreeks of zijdelings in verband staande, zullen in eerste instantie worden berecht door de
                    bevoegde rechter van de rechtbank Noord-Nederland, locatie Groningen.</p>

                <h2>Artikel 8 Wijzigen van de voorwaarden</h2>
                <p>De opdrachtnemer is bevoegd wijzigingen in deze voorwaarden aan te brengen. Deze wijzigingen treden
                    in werking op het aangekondigde tijdstip van inwerkingtreding. De opdrachtnemer zal de gewijzigde
                    voorwaarden tijdig aan de opdrachtgever toezenden. Indien geen tijdstip van inwerkingtreding is
                    medegedeeld, treden wijzigingen wegens de opdrachtgever in werking zodra hem de wijziging is
                    medegedeeld.</p>

            </div>
        </React.Fragment>
    )
}

export default React.memo(BTWTerugvraagAlgemeneVoorwaarden);
