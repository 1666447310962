import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import Helmet from "../../components/Helmet";
import BTWCallToAction from "../../components/BTWCallToAction";
import NavbarSettings from "../../components/navbar/NavbarSettings";
import SubPageBanner from "../../components/SubPageBanner";

function FAQItem({ title, children }) {
    return (
        <div className="card mb-2">
            <div className="card-body">
                <h5 className="card-title">{ title }</h5>
                { children }
            </div>
        </div>
    )
}

function BTWTerugvraagSolar() {
    return (
        <React.Fragment>
            <Helmet title="BTW Terugvraag" description="De BTW op uw zonnepanelen terugvragen"/>
            <NavbarSettings>
                <SubPageBanner
                    title="BTW Terugvraag"
                    subtitle="De BTW op uw zonnepanelen terugvragen"
                />
            </NavbarSettings>

            <div className="container my-5">
                <div className="bigger-paragraphs mb-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-lg-row flex-column align-items-center">
                                <div className="flex-grow-1">
                                    <p className="card-text">
                                        Vanaf 1 januari 2023 is de btw op zonnepanelen afgeschaft voor woningen of bijgebouwen van een
                                        woning.
                                    </p>
                                </div>
                                <div className="ml-md-3">
                                    <Link to="/btw/" className="btn btn-primary mt-3 mt-lg-0" style={{ whiteSpace: "nowrap" }}>
                                        Meer info
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Alert variant="info">
                    <b>Let op:</b> Als u post ontvangt van de belastingdienst over het terugvragen van de BTW op uw
                    zonnepanelen installatie, dan willen wij deze post graag in ontvangst nemen om het proces voort te
                    zetten. Van de roze formulieren hebben wij het orgineel met de blauwe envelop nodig. Overige post
                    kan via een scan per mail verstuurd worden aan{" "}
                    <a href="mailto:btwterugvraag@smithsolar.nl">btwterugvraag@smithsolar.nl</a>
                </Alert>
                <Alert variant="primary">
                    Door de grote drukte bij de belastingdienst (vanwege de enorme opkomst van zonnepanelen) kan het
                    langer duren voordat onze aanvraag bij de belastingdienst in behandeling wordt genomen. Wij vragen u
                    hiervoor begrip te hebben.
                </Alert>

                <p className="lead"><b>Deze service leveren wij alleen aan klanten van Smith Solar B.V.</b></p>

                <p>
                    Als zonnepaneleneigenaar kunt u de btw terugvragen als u zich aanmeldt als btw-ondernemer. Het is
                    mogelijk dit zelf te doen, maar <b className="text-primary">Smith Solar B.V.</b> kan het ook voor u
                    regelen.
                </p>

                <h2>Achtergrond</h2>
                <p>
                    Op 20 juni 2013 bepaalde het Europese Hof dat wanneer een particulier energie opwekt met
                    zonnepanelen en deze energie (gedeeltelijk) terug levert aan het elektriciteitsnet, de particulier
                    ondernemer voor de BTW is. Deze uitspraak is bindend voor Nederland. Op 7 november kwam de
                    Nederlandse belastingdienst met antwoorden op de vele vragen die er maandenlang leefden.
                </p>

                <h2>Hoe werkt het?</h2>
                <p>
                    U kunt het hele BTW-bedrag (voorbelasting) dat in rekening is gebracht voor de aanschaf en
                    installatie van de zonnepanelen in aftrek brengen. Het voordeel dat dit oplevert is groter dan de
                    subsidie van <b>AgentschapNL</b> die in 2012 en 2013 beschikbaar gesteld werd door de overheid
                    (subsidiepot is ondertussen leeg).
                </p>

                <h2>Voorwaarden</h2>
                <p>
                    De BTW kan alleen teruggevraagd worden op installaties die geïnstalleerd zijn vanaf 20 juni 2013 en
                    geldt alleen voor kleinverbruikers (hoofdaansluiting t/m 3x80 Ampère). Ook moet het systeem
                    verbonden zijn met het elektriciteitsnetwerk (geen autonome systemen). Er gelden andere tarieven
                    voor geïntegreerde zonnestroomsystemen waarbij de panelen naast energiebron ook als dakbedekking
                    fungeren.
                </p>
                <p>
                    In de praktijk zijn vrijwel alle particulieren kleinverbruiker en installeert{" "}
                    <b className="text-primary">Smith Solar B.V.</b> vrijwel alleen netverbonden, niet-geïntegreerde
                    (opbouw) zonnestroomsystemen. Vrijwel elke particulier komt dus in aanmerking voor BTW-teruggaaf.
                </p>

                <h2>Geen zin in papierwerk?</h2>
                <p>
                    <b className="text-primary">Smith Solar B.V.</b> kan u aanmelden als BTW-ondernemer en de BTW voor u
                    terugvragen bij de Belastingdienst. Wij vragen dan vervolgens ook een ontheffing van de
                    administratieve verplichting (BTW afdracht) voor u aan, zodat u zelf helemaal niets hoeft te doen.
                </p>
                <p>Hiervoor vragen wij eenmalig &euro;100,- excl. BTW (BTW hiervan kan ook teruggevraagd worden).</p>
                <p>
                    U kunt zich voor deze service aanmelden door het formulier aan het einde van de pagina in te vullen.
                </p>

                <h2>Veelgestelde vragen</h2>
                <FAQItem title="Moet ik mij altijd bij de Belastingdienst melden als ondernemer?">
                    <p className="card-text">
                        U meldt zich alleen als ondernemer als u wilt verzoeken om teruggaaf van de BTW die de
                        investering van het zonnepanelensysteem drukt. U kunt dus zelf beslissen of u zich aanmeldt als
                        btw-ondernemer of niet.
                    </p>
                </FAQItem>
                <FAQItem title="Moet ik de komende jaren dan geen BTW betalen over mijn opgewekte stroom?">
                    <p className="card-text">
                        Zolang het saldo van het BTW-bedrag (te betalen BTW over uw omzet na aftrek van de voorbelasting
                        (BTW) op de aanschaf en installatie van de zonnepanelen) over een kalenderjaar &euro;1,345 of
                        minder is hoeft u geen BTW af te dragen. Dankzij de werking van de zogenoemde
                        Kleine-ondernemersregeling hoeft u pas daadwerkelijk BTW te gaan betalen als het saldo BTWbedrag
                        over een kalenderjaar hoger is dan € 1.345 (U mag dan echter ook geen BTW in rekening brengen).
                        Particuliere eigenaren van zonnepanelen zullen deze grens in de praktijk niet bereiken.
                    </p>
                </FAQItem>
                <FAQItem title="Ik heb mijn zonnepanelen op of ná 20 juni 2013 laten aanbrengen maar mij nog niet aangemeld bij de Belastingdienst. Kan ik de BTW dan nog terugkrijgen op de aanschaf van de zonnepanelen?">
                    <p className="card-text">
                        Ja, ook als u zich nog niet heeft gemeld, kunt u zich alsnog melden en de BTW terugvragen die
                        voor de aanschaf en installatie van de zonnepanelen aan u in rekening is gebracht, als u
                        beschikt over een factuur waarop de BTW afzonderlijk is vermeld en als u voor de BTW ondernemer
                        bent. U moet dit verzoek uiterlijk indienen binnen vijf jaar na het jaar van aanschaf.
                    </p>
                </FAQItem>
                <FAQItem title="Maakt het uit wat voor elektriciteitsmeter ik heb?">
                    <p className="card-text">Nee, dat maakt niet uit.</p>
                </FAQItem>
                <FAQItem title="Wat is de Kleine-ondernemersregeling?">
                    <p className="card-text">
                        De Kleine-ondernemersregeling is een tegemoetkoming voor ondernemers die maar heel weinig BTW
                        hoeven te voldoen. Als u ondernemer bent voor de stroom die u aan uw energiebedrijf levert dan
                        moet u BTW berekenen over het bedrag dat u hiervoor aan uw energiebedrijf in rekening brengt.
                        Ook moet u dan in de meeste gevallen BTW voldoen over de stroom die u via uw zonnepanelen opwekt
                        en rechtstreeks verbruikt. Zolang het per saldo te betalen BTW-bedrag per jaar &euro;1,345 of
                        minder is hoeft u dit bedrag echter niet aan de Belastingdienst te voldoen.
                    </p>
                </FAQItem>
                <FAQItem title="Geldt de BTW teruggaaf ook voor VVE's?">
                    <p className="card-text">
                        VVE’s komen wel in aanmerking voor de BTW-teruggave. Wel moet er dan bij de Belastingdienst
                        gemeld worden dat de VVE energie gaat opwekken. De vrijstelling van administratieve verplichting
                        geldt echter alleen voor natuurlijke personen en dus niet voor een VVE.
                    </p>
                </FAQItem>
                <FAQItem title="Hoe bereken ik de (aftrek van) BTW over het jaar van aanschaf en installatie van zonnepanelen?">
                    <p className="card-text">
                        Op uw BTW-aangifte moet u de verschuldigde BTW aangeven. Hierop mag u de voorbelasting in aftrek
                        brengen. Het saldo-bedrag van die aangifte is het bedrag dat u terugvraagt. Om de verschuldigde
                        BTW te kunnen vaststellen zou u precies moeten weten hoeveel stroom u over een bepaalde periode
                        aan het energiebedrijf levert en hoeveel stroom u direct zelf verbruikt. Ook zou u dan BTW in
                        rekening moeten brengen aan uw energiebedrijf. In de praktijk is dit meestal niet vast te
                        stellen. Mede in afwachting van ontwikkelingen in Europees verband met betrekking tot een
                        eventuele wijziging van de BTW-richtlijn, kunt u daarom voorlopig de hierna genoemde forfaitaire
                        bedragen hanteren die u als verschuldigde belasting op uw aangifte kunt vermelden. De forfaits
                        verschillen naar gelang van het opwekvermogen van uw zonnepanelen. Als u gebruik maakt van deze
                        forfaits mag u geen BTW in rekening brengen aan uw energiebedrijf.
                    </p>

                    <table className="table table-sm" style={{ maxWidth: "400px" }}>
                        <thead>
                        <tr>
                            <th>Opwekvermogen in Wattpiek</th>
                            <th>Forfait</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>0 – 1000 Wp</td>
                            <td>&euro; 20</td>
                        </tr>
                        <tr>
                            <td>1001 – 2000 Wp</td>
                            <td>&euro; 40</td>
                        </tr>
                        <tr>
                            <td>2001 – 3000 Wp</td>
                            <td>&euro; 60</td>
                        </tr>
                        <tr>
                            <td>3001 – 4000 Wp</td>
                            <td>&euro; 80</td>
                        </tr>
                        <tr>
                            <td>4001 – 5000 Wp</td>
                            <td>&euro; 100</td>
                        </tr>
                        </tbody>
                    </table>

                    <h6>Voorbeeld</h6>
                    <p className="card-text">
                        Voor de aanschaf en installatie van zonnepanelen dit op 5 december geplaatst zijn heeft u een
                        rekening ontvangen met daarop een BTW-bedrag van (bijvoorbeeld) &euro; 800. U kunt het hele
                        BTW-bedrag als voorbelasting in aftrek brengen. De zonnepanelen hebben een vermogen van 2.500Wp.
                    </p>
                    <p className="card-text">Forfait verschuldigde BTW: &euro; 60,-</p>
                    <p className="card-text">
                        Dus de btw die u terug krijgt van de belastingdienst is dus uiteindelijk
                        &euro; 800 - &euro; 60 = &euro; 740,-
                    </p>
                </FAQItem>
                <FAQItem title="Meer vragen?">
                    <p className="card-text">
                        Voor andere vragen kunt u mailen naar{" "}
                        <a href="mailto:btwterugvraag@smithsolar.nl">btwterugvraag@smithsolar.nl</a>.
                    </p>
                </FAQItem>

                <Alert variant="info">
                    <b>Let op:</b> Als u post ontvangt van de belastingdienst over het terugvragen van de BTW op uw
                    zonnepanelen installatie, dan willen wij deze post graag in ontvangst nemen om het proces voort te
                    zetten. Van de roze formulieren hebben wij het orgineel met de blauwe envelop nodig. Overige post
                    kan via een scan per mail verstuurd worden aan{" "}
                    <a href="mailto:btwterugvraag@smithsolar.nl">btwterugvraag@smithsolar.nl</a>
                </Alert>
                <Alert variant="primary">
                    Door de grote drukte bij de belastingdienst (vanwege de enorme opkomst van zonnepanelen) kan het
                    langer duren voordat onze aanvraag bij de belastingdienst in behandeling wordt genomen. Wij vragen u
                    hiervoor begrip te hebben.
                </Alert>
            </div>
            <BTWCallToAction/>
        </React.Fragment>
    )
}

export default BTWTerugvraagSolar;
