import React from "react";

import Helmet from "../components/Helmet";
import NavbarSettings from "../components/navbar/NavbarSettings";
import SubPageBanner from "../components/SubPageBanner";
import HomepageImageText from "../components/HomepageImageText";
import HomepageCTABlock from "../components/HomepageCTABlock";

import gridSenseLogo from "../img/gridsense/gridsense-logo.svg";

import banner from "../img/solar-banner.jpg";
import aforeLogo from "../img/ems/afore-logo.svg";
import goodWeLogo from "../img/ems/goodwe-logo.svg";
import solarEdgeLogo from "../img/ems/solaredge-logo.svg";
import macMockup from "../img/ems/mac-mockup-filled.png";
import phoneMockupFilled from "../img/ems/phone-mockup-filled.png";
import batteriesImg from "../img/ems/batteries.png";
import blockSeperator from "../img/svg/block-seperator.svg";

function EMS() {
    return (
        <React.Fragment>
            <Helmet
                title="Energie Management Systeem"
                description="Slimme sturing voor thuisbatterijen kopen? Smith Solar biedt energie management systemen voor slimme sturing op dynamische energieprijzen. Contacteer ons!"
            />

            <NavbarSettings>
                <SubPageBanner
                    title="Energie Management Systeem"
                    subtitle="Slimme sturing op dynamische energieprijzen"
                    image={banner}
                    imgAlt="Afbeelding van zonnepanelen"
                    overlayClass="sub-banner-blue"
                />
            </NavbarSettings>

            <div className="container-blue-background">
                <div className="container">
                    <h2 className="text-center"> Maak kennis met GridSense </h2>
                    <HomepageImageText
                        title="Innovatieve oplossing"
                        titleTag="h3"
                        text="De snel evoluerende wereld van hernieuwbare energie opent de deuren naar innovatieve oplossingen. Energie management systemen bieden de uitkomst om thuisbatterijen van zonnepanelen op een slimme manier te sturen. Of je nu thuis bent of een bedrijf runt, een EMS is een slimme en duurzame technologie om je energieverbruik te optimaliseren en optimaal te beheersen. Bij Smith Solar bieden we een energie management systeem om ervoor te zorgen dat je de thuisbatterij slim kunt sturen. Lees verder en ontdek welke voordelen dit systeem met zich meebrengt."
                        img={macMockup}
                        imgAlt="GridSense dashboard op een Macbook"
                        underLinedTitle={false}
                    />
                </div>
            </div>

            <div className="container-white-background mb-0">
                <div className="container">
                    <HomepageImageText
                        title="Thuisbatterij slim sturen met dynamische energieprijzen"
                        titleTag="h3"
                        text="Zon en windenergie worden gebruikt om groene stroom op te wekken. Bij zon gebeurt dit voornamelijk in de middaguren. Op deze momenten is er minder vraag naar stroom. Dit leidt ertoe dat de overschotten aan groene stroom verloren gaan. Het slim inzetten van thuisbatterijen zorgt ervoor dat huishoudens deze groene stroom van het net kunnen opslaan voor later gebruik of aan het net kunnen verkopen tegen het hoogste tarief. Een energie management systeem draagt bij aan de slimme sturing van thuisbatterijen en beheert het energieverbruik op efficiënte wijze."
                        img={batteriesImg}
                        imgAlt="Thuisbatterijen"
                        underLinedTitle={false}
                    />
                </div>
            </div>

            <div className="container-blue-background pb-0">
                <div>
                    <div className="container">
                        <h2 className="text-center"> Integratie van het energie management systeem </h2>
                        <HomepageImageText
                            text="Het wordt steeds interessanter om je elektriciteitsvraag te spreiden of zo veel mogelijk te verschuiven naar momenten waarop er veel energie ter beschikking is. Een energie management systeem is een technologie die daarbij kan ondersteunen. Dit is een apparaat dat de opgeslagen zonnestroom in je thuisbatterij op een slimme manier inzet. Energiemanagement meet, monitort en beheert het volledige energieverbruik van je woning of bedrijf. Hierbij worden de verschillende energiestromen in kaart gebracht en energiebesparende maatregelen toegepast."
                            img={phoneMockupFilled}
                            imgAlt="GridSense dashboard op een iPhone"
                            underLinedTitle={false}
                            isReverse={true}
                        />
                    </div>
                    <div className="w-full" style={{ height: 200, position: "relative" }}>
                        <img src={blockSeperator} alt="Block seperator" style={{ width: "100%", position: "absolute", objectFit: "cover", bottom: 0 }} />
                    </div>
                </div>
            </div>

            <div className="container-white-background">
                <div className="container">
                    <h2 className="text-center"> Slim energiebeheer met GridSense </h2>
                    <div className="image-text-container">
                        <div className="text-container">
                            <div className="text">Bij Smith Solar bieden we het energie management systeem GridSense, waarmee thuisbatterijen slim worden aangestuurd op basis van dynamische energieprijzen. GridSense laadt de thuisbatterij op met eigen zonnestroom tijdens de laagste prijzen van de dag. Hierdoor maak je heel het jaar optimaal gebruik van je thuisbatterij, ook tijdens de wintermaanden. Ons systeem voorziet ondersteuning voor de SolarEdge-omvormer. Met een SolarEdge systeem wordt de opbrengst van elk afzonderlijk zonnepaneel geoptimaliseerd, resulterend in maximale efficiëntie.</div>
                        </div>
                        <div className="image-container">
                            <div className="multiple-image-container">
                                <div className="img-container">
                                    <img src={goodWeLogo} alt="Logo van goodwe" />
                                </div>
                                <div className="img-container">
                                    <img src={solarEdgeLogo} alt="Logo van solar edge" />
                                </div>
                                <div className="img-container">
                                    <img src={aforeLogo} alt="Logo van Afore" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container container-sm">
                <h2> De voordelen van een energie management systeem </h2>
                <p>Dankzij de automatische sturing van het energie management systeem kan je het rendement van je zonnepanelen aanzienlijk verhogen. Ook ben je minder afhankelijk van het elektriciteitsnet en de hoge elektriciteitsprijzen. Verder kan het energie management systeem pieken in het elektriciteitsgebruik verlagen. Het zorgt ervoor dat je zo voordelig mogelijk gebruikmaakt van je dynamische elektriciteitscontract.</p>
            </div>

            <div className="container container-sm">
                <h2> Contact </h2>
                <p>Nieuwsgierig naar de mogelijkheden van een energie management systeem voor thuis of in je bedrijf? Wij vertellen je graag meer over de voordelen dat ons systeem kan bieden voor jouw specifieke woonsituatie. Door een offerte te laten opmaken kom je meteen te weten wat de bijbehorende kosten zijn van deze slimme investering.
                    Heb je verder nog specifieke vragen over het implementeren van een energie management systeem? Neem dan contact op met ons. Smith Solar staat klaar om je te adviseren over de slimme sturing van je thuisbatterij en je te begeleiden bij dit proces.</p>
            </div>

            <HomepageCTABlock
                title="Meer weten over GridSense?"
                text="Nieuwsgierig naar de mogelijkheden van een energie management systeem voor thuis of in je bedrijf? Wij vertellen je graag meer over de voordelen dat ons systeem kan bieden voor jouw specifieke woonsituatie. Door een offerte te laten opmaken kom je meteen te weten wat de bijbehorende kosten zijn van deze slimme investering.
                Heb je verder nog specifieke vragen over het implementeren van een energie management systeem? Neem dan contact op met ons. Smith Solar staat klaar om je te adviseren over de slimme sturing van je thuisbatterij en je te begeleiden bij dit proces."
                buttonText="Neem contact op"
                buttonLink="/contact"
                img={gridSenseLogo}
                imgAlt="Gridsense logo"
            />

        </React.Fragment>
    );
}

export default React.memo(EMS);
