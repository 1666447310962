import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";
import CallToAction from "../../components/CallToAction";
import MidCallToAction from "../../components/MidCallToAction";
import VerticalCard from "../../components/VerticalCard";
import NavbarSettings from "../../components/navbar/NavbarSettings";
import SubPageBanner from "../../components/SubPageBanner";
import HomepageImageText from "../../components/HomepageImageText";

import banner from "../../img/solar-banner.jpg";
import solaredgeBatteries from "../../img/producten/solaredge/solaredge-batteries.png";

function ThuisbatterijInstallatie() {
    return (
        <React.Fragment>
            <Helmet
                title="Thuisbatterij installatie"
                description="Maak je zonnepanelen nog efficiënter met een thuisbatterij installatie van Smith Solar en wordt energieonafhankelijk. Vraag vandaag nog een offerte aan!"
            />

            <NavbarSettings>
                <SubPageBanner
                    title="Thuisbatterij"
                    subtitle="Installatie"
                    image={banner}
                    imgAlt="Foto van zonnepanelen"
                />
            </NavbarSettings>

            <div className="container-orange-background">
                <div className="container">
                    <div className="image-text-container">
                        <div className="text-container">
                            <h2 className="title">Hoogwaardige thuisbatterijen</h2>
                            <div className="text">Bij Smith Solar streven we ernaar om huiseigenaren te voorzien van geavanceerde, duurzame
                                energieoplossingen door de installatie van hoogwaardige thuisbatterijen. Ontdek hoe ons
                                stap-voor-stap proces zorgt voor een naadloze integratie van thuisbatterijen in je woning.
                            </div>
                        </div>
                        <div className="image-container">
                            <img src={solaredgeBatteries} alt="foto van thuisbatterij" className="cover overflow-box"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-5 container-white-background">
                <div className="container mt-4 text-black">

                    <h2 className="mb-4 text-center">
                        Is jouw woning geschikt voor de installatie van een thuisbatterij?
                    </h2>

                    <p className="text-center">
                        De overgang naar een duurzamere toekomst begint met de beoordeling of je woning geschikt is voor een thuisbatterij.
                    </p>

                    <div className="row">
                        <div className="col-xl-3 col-md-6 my-3">
                            <h3 style={{fontSize: "1.3rem"}}>
                                Beschikbare ruimte
                            </h3>
                            <p>
                                Een technische ruimte, garage of zolder is ideaal voor het plaatsen van de
                                thuisbatterij. Zorg dat er genoeg ruimte is, een thuisbatterij is over het algemeen een
                                vrij grote kast.
                            </p>
                        </div>
                        <div className="col-xl-3 col-md-6 my-3">
                            <h3 style={{fontSize: "1.3rem"}}>
                                Type zonnepanelen
                            </h3>
                            <p>
                                Bestaande zonnepanelen optimaliseren de voordelen van een thuisbatterij door overtollige
                                energie op te slaan.
                            </p>
                        </div>
                        <div className="col-xl-3 col-md-6 my-3">
                            <h3 style={{fontSize: "1.3rem"}}>
                                Aansluiting
                            </h3>
                            <p>
                                Voor sommige thuisbatterijen is een 3-fasen aansluiting nodig. Controleer of je deze
                                aansluiting hebt of dat deze geplaatst moet worden.
                            </p>
                        </div>
                        <div className="col-xl-3 col-md-6 my-3">
                            <h3 style={{fontSize: "1.3rem"}}>
                                Energiebehoeften
                            </h3>
                            <p>
                                Zorg ervoor dat je energiebehoeften aansluiten bij de capaciteit van de thuisbatterij om
                                maximale voordelen te behalen.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container mt-5 mb-4">

                <h2>
                    Een thuisbatterij installeren in 6 stappen
                </h2>

                <div className="row mt-3">
                    <div className="col-md-6 col-lg-4 mb-3">
                        <VerticalCard title="Stap 1">
                            <p>
                                <Link to="/offerte/">
                                    Vraag een offerte aan
                                </Link>
                            </p>
                        </VerticalCard>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-3">
                        <VerticalCard title="Stap 2">
                            <p>
                                Plan een adviesgesprek in voor persoonlijk advies.
                            </p>
                        </VerticalCard>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-3">
                        <VerticalCard title="Stap 3">
                            <p>
                                Wij stellen een eerste offerte op.
                            </p>
                        </VerticalCard>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-3">
                        <VerticalCard title="Stap 4">
                            <p>
                                We maken een afspraak op locatie om eventuele knelpunten te voorkomen.
                            </p>
                        </VerticalCard>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-3">
                        <VerticalCard title="Stap 5">
                            <p>
                                Onze ervaren monteurs komen de thuisbatterij installeren.
                            </p>
                        </VerticalCard>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-3">
                        <VerticalCard title="Stap 6">
                            <p>
                                Wij houden de werking van je thuisbatterij in de gaten en finetunen waar nodig.
                            </p>
                        </VerticalCard>
                    </div>
                </div>

            </div>

            <div className="container">
                <MidCallToAction/>
            </div>

            <div className="container container-sm">

                <h2>
                    Het installatieproces uitgelegd
                </h2>
                <p>
                    Maak kennis met ons zorgvuldig ontworpen proces.
                </p>

                <h3>
                    Vraag een offerte aan
                </h3>
                <p>
                    Het proces begint met het aanvragen van een offerte. Dit geeft ons een eerste inzicht in jouw
                    specifieke situatie en stelt ons in staat om een voorstel op maat te maken.{" "}
                    <Link to="/offerte/">Vul het offerteformulier in</Link>{" "}
                    en wij nemen zo snel mogelijk contact met je op.
                </p>

                <h3>
                    Persoonlijk advies
                </h3>
                <p>
                    Na de offerteaanvraag volgt een persoonlijk adviesgesprek. Tijdens dit gesprek verkennen we je
                    wensen en de specifieke kenmerken van jouw woning. Dit stelt ons in staat om een oplossing op maat
                    te bieden die aansluit bij jouw energiebehoeften en levensstijl.
                </p>

                <h3>
                    Eerste offerte
                </h3>
                <p>
                    Gebaseerd op het adviesgesprek, stellen we een gedetailleerde offerte samen. Deze offerte omvat alle
                    kosten, verwachte energiebesparingen en de terugverdientijd, zodat je een weloverwogen beslissing
                    kunt nemen.
                </p>

                <h3>
                    Afspraak op locatie
                </h3>
                <p>
                    Een grondige inspectie op locatie door onze technische experts helpt potentiële knelpunten te
                    identificeren en te adresseren. Dit zorgt ervoor dat de installatie soepel en efficiënt verloopt,
                    aangepast aan de unieke eigenschappen van je woning.
                </p>

                <h3>
                    Installatiedag
                </h3>
                <p>
                    Op de installatiedag komen onze ervaren monteurs naar je huis om de thuisbatterij te installeren. Ze
                    zorgen voor een professionele installatie en koppeling met je bestaande zonnepanelen, met minimale
                    overlast.
                </p>

                <h3>
                    Finetuning
                </h3>
                <p>
                    Na de installatie voeren we finetuning uit om ervoor te zorgen dat de thuisbatterij optimaal
                    functioneert. Dit omvat het monitoren van de prestaties en het aanpassen van de instellingen om de
                    efficiëntie en levensduur van je thuisbatterij te maximaliseren.
                </p>

                <h2 className="mt-5">
                    Nazorg
                </h2>
                <p>
                    Onze service stopt niet na de installatie. Wij bieden nazorg om ervoor te zorgen dat je
                    thuisbatterij blijft voldoen aan je verwachtingen. Voor vragen of aanvullende ondersteuning kun je
                    altijd <Link to="/support/">contact met ons opnemen</Link>.
                </p>

            </div>

            <CallToAction/>

        </React.Fragment>
    )
}

export default React.memo(ThuisbatterijInstallatie);
