import React from "react";
import moment from "moment";
import Helmet from "../components/Helmet";

import CallToAction from "../components/CallToAction";
import NavbarSettings from "../components/navbar/NavbarSettings";
import SubPageBanner from "../components/SubPageBanner";

function Contact() {
    let hour = moment().hour();
    let opened = hour >= 8 && hour < 17;
    let weekDay = moment().isoWeekday();
    if (weekDay === 6 || weekDay === 7) opened = false;

    return (
        <React.Fragment>
            <Helmet title="Contact"/>

            <NavbarSettings>
                <SubPageBanner
                    title="Contact"
                />
            </NavbarSettings>

            <div className="container my-5">
                { opened ? (
                    <React.Fragment>
                        <h2 className="mb-0">
                            Bel <a href="tel:+31174235120">0174 235 120</a>
                        </h2>
                        <h3 className="text-muted mt-0">
                            <small>Bereikbaar tot 17:00</small>
                        </h3>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <h2 className="mb-0">
                            Mail naar <a href="mailto:info@smithsolar.nl">info@smithsolar.nl</a>
                        </h2>
                        <h3 className="text-muted mt-0">
                            <small>Geopend vanaf 8:00 { (weekDay === 6 || weekDay === 7) && "op maandag" }</small>
                        </h3>
                    </React.Fragment>
                )}
                <p style={{ fontSize: "20px" }} className="mt-4">
                    Smith Solar B.V.<br/>
                    Aartsdijkweg 45<br/>
                    2676 LE Maasdijk<br/>
                    The Netherlands<br/>
                    <br/>
                    Telefoon: <a href="tel:+31174235120">0174 235 120</a><br/>
                    Email: <a href="mailto:info@smithsolar.nl">info@smithsolar.nl</a><br/>
                    KvK: 60218452
                </p>
            </div>
            <iframe id="map" title="map" width="100%" height="400" frameBorder="0" style={ { border: 0 } }
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2458.7671404261887!2d4.22602857774095!3d51.95643767191885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5b3262067eb81%3A0x303e1debc51b32c8!2sAartsdijkweg%2045%2C%202676%20LE%20Maasdijk!5e0!3m2!1snl!2snl!4v1730277908892!5m2!1snl!2snl" allowFullScreen/>
            <CallToAction/>
        </React.Fragment>
    );
}

export default Contact;
