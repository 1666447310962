const OverzichtCallToAction = ({ title, subtitle, buttonLink, buttonText }) => {
    return (
        <div className="container container-sm">
            <div className="overzicht-cta">
                <h2 className="title">{title}</h2>
                <p className="subtitle">{subtitle}</p>
                <a href={buttonLink} className="button">
                    {buttonText}
                </a>
            </div>
        </div>
    );
};

export default OverzichtCallToAction;
