const Reviews = ({ title, reviews }) => {
    return (
        <div className="reviews-container">
            <div className="reviews-header">
                <h2 className="title">{title}</h2>
            </div>
            <div className="reviews">
                {reviews.map((review, index) => (
                    <Review key={index} review={review} />
                ))}
            </div>
        </div>
    );
};

const Review = ({ review }) => {
    const { title, description, rating, source, url } = review;

    return (
        <a className="review-item" href={url} target="_blank" rel="noreferrer">
            <div>
                <i class="fa-solid fa-quote-right fa-2xl" />
                <div className="content">
                    <h3 className="title">{title}</h3>
                    <p className="description">{description}</p>
                </div>
            </div>
            <div>
                <StarRating rating={rating} />
                <div className="source">
                    <i class="fa-brands fa-google"></i>
                    <p>{source}</p>
                </div>
            </div>
        </a>
    );
};

const StarRating = ({ rating }) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    return (
        <div className="stars">
            {Array(fullStars)
                .fill()
                .map((_, i) => (
                    <i class="fa-solid fa-star fa-xl star-icon" />
                ))}
            {hasHalfStar && <i class="fa-solid fa-star-half-stroke fa-xl star-icon" />}
            {Array(emptyStars)
                .fill()
                .map((_, i) => (
                    <i class="fa-regular fa-star star-icon fa-xl" />
                ))}
        </div>
    );
};

export default Reviews;
