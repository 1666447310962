import React, {
    useEffect,
    useState
} from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";
import CallToAction from "../../components/CallToAction";
import useWindowSize from "../../hooks/WindowSize";
import JumbotronCallToAction from "../../components/JumbotronCallToAction";
import BlackFridayCallToAction from "../../components/BlackFridayCallToAction";

import banner from "../../img/actie/actie-background.jpg";
import circle from "../../img/actie/actie-circle.svg";
import NavbarSettings from "../../components/navbar/NavbarSettings";

function MobileActie() {
    const [bannerHeight, setBannerHeight] = useState(0);
    const windowSize = useWindowSize();

    useEffect(() => {
        let height = window.innerHeight;
        if(window.innerWidth <= 575) {
            height -= 80;
            height *= 0.9;
        } else {
            height -= 150;
        }
        setBannerHeight(Math.max(height, 400));
    }, [windowSize.orientation]);

    return (
        <React.Fragment>
            <Helmet
                title="Profiteer van de zon! - Actie"
                description="8 panelen incl. montage vanaf € 2.965,- excl. btw. Vraag binnen 2 minuten een offerte aan!"
            />

            <NavbarSettings/>

            <div
                className="actie-banner"
                style={{
                    backgroundImage: "url('" + banner + "')",
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                }}
            >
                <div
                    className="container-fluid w-100 gradient"
                >
                    <div
                        className="d-flex flex-column justify-content-end w-100 py-3 py-md-5 px-sm-3 px-lg-5"
                        style={{
                            minHeight: bannerHeight + "px",
                        }}
                    >
                        <div className="flex-grow-1 d-block d-sm-none">
                            <img
                                src={ circle }
                                alt="Actie prijs"
                                className="circle"
                            />
                        </div>
                        <div className="d-flex flex-column flex-sm-row">
                            <div className="flex-grow-1 d-flex flex-column justify-content-end text-white">
                                <h1
                                    className="mb-0 title"
                                >
                                    Profiteer
                                </h1>
                                <h1
                                    className="mb-0 ml-5 title"
                                >
                                    van de zon!
                                </h1>
                            </div>
                            <div className="d-none d-sm-block">
                                <img
                                    src={ circle }
                                    alt="Actie prijs"
                                    className="circle"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pt-3 pt-sm-5">

                <JumbotronCallToAction className="mt-0 mt-sm-3 mb-4"/>

                <BlackFridayCallToAction/>

                <h2>Waarom zonnepanelen?</h2>

                <div className="card-rounded mb-3">
                    <div className="row">
                        <div className="col-lg-1half col-xl-1 text-center">
                            <i className="fas fa-clock fa-fw text-primary" style={{fontSize: "60px"}}/>
                            <br/><br/>
                        </div>
                        <div className="col-lg-10half col-xl-11">
                            <h4 className="card-title">4-6 jaar terugverdientijd</h4>
                            <p className="card-text">
                                Onze prijzen zijn scherp. Daarom is het mogelijk om uw volledige zonnepanelen
                                installatie, inclusief materiaal en montage, terug te verdienen binnen 4 tot 6 jaar!
                            </p>
                        </div>
                    </div>
                </div>

                <div className="card-rounded mb-3">
                    <div className="row">
                        <div className="col-lg-1half col-xl-1 text-center">
                            <i className="fas fa-home fa-fw text-primary" style={{fontSize: "60px"}}/>
                            <br/><br/>
                        </div>
                        <div className="col-lg-10half col-xl-11">
                            <h4 className="card-title">0% btw over zonnepanelen</h4>
                            <p className="card-text">
                                In 2023 hoeft over zonnepanelen voor woonhuizen geen btw betaalt te worden. Ontdek
                                via onderstaande knop hoe dit precies zit.
                            </p>
                            <Link to="/btw/" className="btn btn-primary">Meer informatie</Link>
                        </div>
                    </div>
                </div>

                <div className="card-rounded mb-3">
                    <div className="row">
                        <div className="col-lg-1half col-xl-1 text-center">
                            <i className="fas fa-map-marker-alt fa-fw text-primary" style={{fontSize: "60px"}}/>
                            <br/><br/>
                        </div>
                        <div className="col-lg-10half col-xl-11">
                            <h4 className="card-title">Opname op locatie</h4>
                            <p className="card-text">
                                Wij maken een afspraak met u na uw offerte aanvraag om met u de offerte door te
                                nemen. Ook kijken we nog extra goed naar uw dak zodat we nog aanpassingen aan de
                                offerte kunnen maken. Dit zorgt voor een probleemloze montage.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="card-rounded mb-3">
                    <div className="row">
                        <div className="col-lg-1half col-xl-1 text-center">
                            <i className="fas fa-star fa-fw text-primary" style={{ fontSize: "60px" }}/>
                            <br/><br/>
                        </div>
                        <div className="col-lg-10half col-xl-11">
                            <h4 className="card-title">Lees want klanten van ons zeggen</h4>
                            <p className="card-text">
                                Klanten die u voor zijn gegaan, beoordelen ons met een <b className="b-solar">8.9</b>. Kijk wat zij van ons zeggen.
                            </p>
                            <a href="https://referenties.smithsolar.nl/reviews" className="btn btn-primary">Recensies</a>
                        </div>
                    </div>
                </div>
            </div>

            <CallToAction title={
                <React.Fragment>
                    <small className="text-muted">
                        Bent u er klaar voor?
                    </small>
                    <br/>
                    Vraag vandaag nog een offerte aan!
                </React.Fragment>
            }/>
        </React.Fragment>
    )
}

export default React.memo(MobileActie);
