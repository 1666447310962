import React from "react";
import Helmet from "../../components/Helmet";
import NavbarSettings from "../../components/navbar/NavbarSettings";
import SubpageBanner from "../../components/SubPageBanner";

import afore1fase from "../../files/confirmiteitscertificaten/afore-1fase-conformiteitscertificaat.pdf";
import afore3fase from "../../files/confirmiteitscertificaten/afore-3fase-conformiteitscertificaat.pdf";
import solaredge1fase from "../../files/confirmiteitscertificaten/solaredge-1fase-conformiteitscertificaat.pdf";
import solaredge3fase from "../../files/confirmiteitscertificaten/solaredge-3fase-conformiteitscertificaat.pdf";
import enphaseIQ7 from "../../files/confirmiteitscertificaten/enphase-iq7.pdf";
import enphaseIQ7A from "../../files/confirmiteitscertificaten/enphase-iq7a.pdf";
import solis from "../../files/confirmiteitscertificaten/solis.pdf";
import solplanet1fase from "../../files/confirmiteitscertificaten/solplanet-1fase.pdf";

function ConformiteitsLink({ file, name }) {
    return (
        <li>
            <a href={ file } target="_blank" rel="noopener noreferrer">
                { name }
            </a>
        </li>
    )
}

function Conformiteitscertificaat() {
    return (
        <React.Fragment>
            <Helmet title="Conformiteitscertificaat omvormers"/>

            <NavbarSettings>
                <SubpageBanner
                    title="Conformiteitscertificaat omvormers"
                />
            </NavbarSettings>

            <div className="container mt-5">
                <p>
                    Krijg jij een zonnepaneleninstallatie, wordt jouw omvormer vervangen of wordt jouw installatie
                    uitgebreid? Dan moet je als eigenaar van een zonnepaneleninstallatie vanaf 27 april 2021 verplicht
                    een conformiteitscertificaat voor de omvormer bezitten. Hiermee toon jij aan dat jouw opweksysteem
                    geschikt is voor de aansluiting op het Nederlandse energienet.
                </p>

                <h2>Wat houdt een conformiteitscertificaat in?</h2>
                <p>
                    Het conformiteitscertificaat is een officieel document dat bevestigt dat jouw
                    zonnepaneleninstallatie voldoet aan de Europese richtlijnen voor stroomproductie, ook wel de{" "}
                    <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=OJ:JOL_2016_112_R_0001" target="_blank" rel="noopener noreferrer">
                        Requirements for Generators
                    </a>{" "}
                    (RfG). Het gaat daarbij om de Europese normen EN 50549-1 voor laagspanningsnetten en EN 50549-2 voor
                    middenspanningsnetten.
                </p>
                <p>
                    Ook de omvormers die Smith Solar B.V. gebruikt voldoen aan deze eisen. Hieronder zijn de
                    conformiteitscertificaten van onze verschillende omvormers te vinden.
                </p>

                <ul>
                    <ConformiteitsLink file={ afore1fase } name="Afore 1-fase omvormer"/>
                    <ConformiteitsLink file={ afore3fase } name="Afore 3-fase omvormer"/>
                    <ConformiteitsLink file={ solaredge1fase } name="SolarEdge 1-fase omvormer"/>
                    <ConformiteitsLink file={ solaredge3fase } name="SolarEdge 3-fase omvormer"/>
                    <ConformiteitsLink file={ enphaseIQ7 } name="Enphase IQ7, IQ7X, IQ7+"/>
                    <ConformiteitsLink file={ enphaseIQ7A } name="Enphase IQ7A"/>
                    <ConformiteitsLink file={ solis } name="Solis"/>
                    <ConformiteitsLink file={ solplanet1fase } name="Solplanet"/>
                    <ConformiteitsLink name="SMA download pagina" file="https://www.sma.de/en/service/downloads"/>
                </ul>

                <h2>Actief monitoren door netbeheerder</h2>
                <p>
                    Vanaf de eind april wordt het bezit van de conformiteitscertificaten ook actief gemonitord door
                    Nederlandse netbeheerders. Zij kijken naar nieuwe aanmeldingen via{" "}
                    <a href="https://energieleveren.nl" target="_blank" rel="noopener noreferrer">
                        Energieleveren.nl
                    </a>{" "}
                    en de directe meldingen bij de netbeheerder zelf.
                </p>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Conformiteitscertificaat);
