import React from "react";

import NavbarLink from "../NavbarLink";

function ThuisbatterijSubNavbar() {
    return (
        <>
            <NavbarLink to="/thuisbatterij/" exact>
                <i className="fa-solid fa-house fa-fw mr-2" />
                Overzicht
            </NavbarLink>
            <NavbarLink to="/thuisbatterij/hoe-werkt-het/">
                <i className="fa-solid fa-gear-complex fa-fw mr-2" />
                Hoe werkt het?
            </NavbarLink>
            <NavbarLink to="/thuisbatterij/installatie/">
                <i className="fa-solid fa-tools fa-fw mr-2" />
                Installatie
            </NavbarLink>
            <NavbarLink to="/thuisbatterij/zakelijk/">
                <i className="fa-solid fa-briefcase fa-fw mr-2"/>
                Zakelijk
            </NavbarLink>
            <NavbarLink to="/thuisbatterij/btwterugvraag/">
                <i className="fa-solid fa-circle-euro fa-fw mr-2" />
                BTW Terugvragen
            </NavbarLink>
        </>
    );
}

export default React.memo(ThuisbatterijSubNavbar);
