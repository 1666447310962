import React from "react";
import { Link } from "react-router-dom";

import Helmet from "../../components/Helmet";
import CallToAction from "../../components/CallToAction";
import MidCallToAction from "../../components/MidCallToAction";
import NavbarSettings from "../../components/navbar/NavbarSettings";
import SubPageBanner from "../../components/SubPageBanner";
import HomepageImageText from "../../components/HomepageImageText";

import daelInstallation from "../../img/zonnepanelen/deal-zonnepanelen-installatie-min.jpg";

function ZonnepanelenZakelijk() {
    return (
        <React.Fragment>
            <Helmet
                title="Zakelijk zonnepanelen"
                description="Ontdek het aanbod zakelijke zonnepanelen bij Smith Solar. Hoge kwaliteit, grote opbrengstgarantie en tot 12 jaar fabrieksgarantie. Vraag nu een offerte aan!"
            />

            <NavbarSettings>
                <SubPageBanner title="Zonnepanelen" subtitle="Zakelijk" image={daelInstallation} />
            </NavbarSettings>

            <div className="container-orange-background mb-5">
                <div className="container">
                    <HomepageImageText
                        title="Zakelijke zonnepanelen"
                        text="Duurzaamheid is niet langer een trend, het is de nieuwe norm. Bedrijven wereldwijd zetten de stap naar een groenere toekomst.
                        Zakelijke zonnepanelen zijn de sleutel tot een duurzame bedrijfsvoering, waarbij je niet alleen je energiekosten verlaagt,
                        maar ook je ecologische voetafdruk aanzienlijk verkleint. Bij Smith Solar brengen we meer dan 10 jaar expertise in
                        grootschalige zonne-energieprojecten om jouw bedrijf te helpen de kracht van de zon optimaal te benutten. Ontdek hoe wij jouw
                        onderneming kunnen transformeren met slimme, efficiënte zonne-energieoplossingen."
                        img={daelInstallation}
                        imgAlt="Foto van zonnepanelen"
                        coverImage={true}
                    />
                </div>
            </div>

            <div className="container container-sm">

                <h3>Zakelijke zonnepanelen kopen: een slimme investering</h3>
                <p>
                    Zonnepanelen voor zakelijk gebruik zijn meer dan alleen een milieuvriendelijke keuze; ze zijn een slimme investering in de
                    toekomst van je bedrijf. Met zakelijke zonnepanelen kun je de energiekosten op zakelijk gebied aanzienlijk verlagen, waardoor je
                    meer financiële ruimte hebt om te investeren in andere belangrijke bedrijfsaspecten. Bovendien verhoogt het installeren van
                    zonnepanelen de waarde van je bedrijfspand, wat een blijvend voordeel biedt.
                </p>

                <h4 className="text-muted font-weight-normal" style={{ fontSize: "1.5rem" }}>
                    Toepassingen van zakelijke zonnepanelen
                </h4>
                <p>
                    Bij Smith Solar bieden we op maat gemaakte oplossingen voor diverse zakelijke toepassingen. Of je nu een kantoorgebouw hebt, een
                    veldopstelling wilt realiseren, of de energie-efficiëntie van je kassen wilt verbeteren, wij hebben de expertise om dit mogelijk
                    te maken.
                </p>
                <ul>
                    <li>
                        <strong>Kantoorgebouwen</strong>: zakelijke zonnepanelen voor kantoorgebouwen bieden een efficiënte manier om te profiteren
                        van zonne-energie, met een laag onderhoud en hoge opbrengst.
                    </li>
                    <li>
                        <strong>Veldopstellingen</strong>: voor bedrijven met ruime buitenlocaties zijn veldopstellingen een ideale manier om
                        grootschalige zonne-energie te genereren.
                    </li>
                    <li>
                        <strong>Kassen</strong>: optimaliseer de energie-efficiëntie van je kassen met onze op maat gemaakte zonnepanelenoplossingen.
                    </li>
                </ul>
            </div>

            <div className="container">
                <MidCallToAction />
            </div>

            <div className="container container-sm">
                <h3>De ervaring en expertise van Smith Solar</h3>
                <p>
                    Met ruim 10 jaar ervaring in grootschalige zakelijke zonnepaneleninstallaties, is Smith Solar een naam waarop je kunt vertrouwen.
                    Onze deskundige installateurs zorgen ervoor dat je zonnepanelen snel en efficiënt worden geïnstalleerd, zodat je bedrijf zo snel
                    mogelijk kan profiteren van de voordelen van zonne-energie. Bekijk onze eerdere projecten om te zien hoe we bedrijven zoals het
                    jouwe hebben geholpen met de overstap naar duurzame energie.
                </p>

                <h3>Optimalisatie van uw systeem: SolarEdge optimizers</h3>
                <p>
                    Het maximaliseren van de energieopbrengst van je zonnepanelen is essentieel, vooral in situaties waar schaduw een rol speelt.
                    Daarom gebruiken wij SolarEdge optimizers, die ervoor zorgen dat elk zonnepaneel in je systeem optimaal presteert, zelfs bij
                    schaduwwerking. Deze technologie helpt je om het meeste uit je investering te halen, door ervoor te zorgen dat elke zonnestraal
                    optimaal wordt benut.
                </p>

                <h3>Installatie-opties: zuid of oost/west opstellingen</h3>
                <p>
                    Bij het installeren van zakelijke zonnepanelen is de oriëntatie van de panelen van cruciaal belang voor het maximaliseren van de
                    energieopbrengst. Afhankelijk van de locatie en beschikbare ruimte, kan Smith Solar zowel zuidgerichte als oost/west-georiënteerde
                    opstellingen aanbieden. Deze flexibele benadering zorgt ervoor dat we altijd de best mogelijke oplossing kunnen bieden, ongeacht
                    de specifieke omstandigheden van jouw bedrijfslocatie.
                </p>

                <h3>Vraag vandaag nog een offerte aan</h3>
                <p>
                    Zet vandaag de stap naar een duurzame toekomst met zakelijke zonnepanelen van Smith Solar. Vraag eenvoudig een{" "}
                    <Link to="/offerte">vrijblijvende</Link> offerte aan of neem <Link to="contact">contact</Link> met ons op voor meer informatie.
                    Ons team van experts staat klaar om al je vragen te beantwoorden en je te begeleiden bij elke stap van het proces.
                </p>
            </div>

            <CallToAction />
        </React.Fragment>
    );
}

export default React.memo(ZonnepanelenZakelijk);
