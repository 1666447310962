import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";
import CallToAction from "../../components/CallToAction";
import NavbarSettings from "../../components/navbar/NavbarSettings";
import SubpageBanner from "../../components/SubPageBanner";

import bydLvs from "../../img/battery/byd-lvs.png";

function ProductBYDLVS() {
    return (
        <React.Fragment>
            <Helmet
                title="BYD Battery-Box Premium LVS"
                description="Ontdek BYD Battery-Box Premium LVS: duurzaam, onafhankelijk, en betrouwbaar. Verhoog je huiswaarde met deze groene energiebron. Vraag nu een offerte aan!"
            />
            <NavbarSettings>
                <SubpageBanner
                    title="BYD Battery-Box Premium LVS"
                />
            </NavbarSettings>

            <div className="container container-sm mt-5">

                <div className="article-open">
                    <p>
                        Ontdek de geavanceerde BYD Battery-Box Premium LVS. Deze hoogwaardige thuisbatterij tilt jouw
                        energiebeheer naar een hoger niveau en stelt je in staat om optimaal te profiteren van duurzame
                        energieopslag in je woning. Maak kennis met de BYD Battery-Box Premium LVS en krijg meer
                        controle over je energieverbruik met deze innovatieve thuisbatterij!
                    </p>
                </div>

                <div
                    className="card-rounded w-100 text-center my-4 py-3"
                >
                    <img
                        src={ bydLvs }
                        alt="BYD Battery-Box Premium LVS"
                        className="img-fluid"
                        style={ { maxHeight: "200px" } }
                    />
                </div>

                <h2>
                    Wat is een BYD Battery-Box Premium LVS?
                </h2>
                <p>
                    De BYD Battery-Box Premium LVS is een thuisbatterij die is ontworpen voor optimale energieopslag.
                    Doordat de thuisbatterij modulair ontworpen is, kan de BYD Battery Box Premium LVS zelfs op zolder
                    geïnstalleerd worden. De BYD Battery biedt ondersteuning voor 3-fase SolarEdge omvormers en is
                    uitbreidbaar van 4 kWh tot 24 kWh per omvormer, waardoor je de opslagcapaciteit kunt aanpassen aan
                    jouw behoeften.
                </p>
                <p>
                    De BYD Battery voldoet aan de strengste veiligheidseisen en heeft een indrukwekkende efficiëntie van
                    meer dan 95%, waardoor je verzekerd bent van betrouwbare energieopslag. Bij de aanschaf van een BYD
                    Battery-Box Premium LVS krijg je standaard 10 jaar garantie op jouw thuisbatterij.
                </p>

                <h2>
                    Wat zijn de voordelen van een BYD Battery?
                </h2>
                <p>
                    De BYD Battery-Box Premium LVS biedt niet alleen duurzame energieopslag, maar verhoogt ook jouw
                    onafhankelijkheid, maximaliseert de efficiëntie van je zonnepanelen en zorgt voor betrouwbare
                    back-upstroom in noodgevallen.
                </p>

                <h3>
                    Duurzaam
                </h3>
                <p>
                    De BYD Battery-Box Premium LVS staat garant voor duurzaamheid. Met hoogwaardige materialen en
                    geavanceerde productietechnieken draagt deze batterij bij aan een groenere levensstijl. <b>Verminder
                    je
                    ecologische voetafdruk</b> en maak een bewuste keuze voor duurzame energieopslag.
                </p>
                <h3>
                    Opslaan van extra stroom
                </h3>
                <p>
                    De thuisbatterij stelt je in staat om overtollige zonne-energie op te slaan die gedurende de dag
                    wordt opgewekt. Op momenten dat de zon niet schijnt, gebruik je deze opgeslagen energie om je huis
                    van stroom te voorzien, waardoor je <b>je eigen groene energiebron</b> hebt.
                </p>
                <h3>
                    Back-upstroom voor noodgevallen
                </h3>
                <p>
                    Een van de opvallende voordelen van de BYD Battery-Box Premium LVS is de mogelijkheid tot het
                    <b>leveren van back-upstroom tijdens noodgevallen</b>. Als het reguliere elektriciteitsnet uitvalt,
                    zorgt
                    de thuisbatterij ervoor dat je essentiële apparaten kunt blijven gebruiken.
                </p>
                <h3>
                    Waarde huis verhogen
                </h3>
                <p>
                    Investeren in een BYD Battery Box Premium LVS kan de <b>waarde van je huis verhogen</b>. Potentiële
                    kopers
                    zijn steeds meer geïnteresseerd in woningen met geavanceerde energieoplossingen. Maak jouw huis
                    toekomstbestendig en waardevoller met deze innovatieve thuisbatterij.
                </p>
                <h3>
                    Minder afhankelijk van het energienet
                </h3>
                <p>
                    Met een BYD Battery-Box Premium LVS kun je onafhankelijk worden van het reguliere elektriciteitsnet.
                    Hierdoor ben je <b>minder afhankelijk van schommelingen in de energieprijzen</b> en heb je meer
                    controle
                    over je eigen energievoorziening.
                </p>

                <h2>Contact</h2>
                <p>
                    Heb je vragen over de BYD Battery-Box Premium LVS of wil je meer informatie over onze
                    thuisbatterijoplossingen? Aarzel dan niet om <Link to="/contact/">contact</Link> op te nemen met ons
                    deskundige team. We staan
                    klaar om al jouw vragen te beantwoorden en je te helpen bij het maken van de juiste keuze voor jouw
                    specifieke behoeften.
                </p>

                <h2>
                    Offerte aanvragen voor een APsystems micro omvormer
                </h2>
                <p>
                    Ben je overtuigd van de voordelen van de BYD Battery-Box Premium LVS? Vraag vandaag nog geheel
                    vrijblijvend een <Link to="/offerte/">offerte</Link> aan bij Smith Solar B.V. en ontdek hoe deze
                    geavanceerde thuisbatterij bij kan dragen aan een duurzamere en energie-efficiëntere toekomst.
                </p>

                <CallToAction
                    title="Waar wacht je nog op?"
                    subtitle="Vraag vandaag nog een offerte aan!"
                />

            </div>
        </React.Fragment>
    );
}

export default React.memo(ProductBYDLVS);
