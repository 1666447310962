import React, { useState, useEffect, useContext, useCallback } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import NavbarLink from "./NavbarLink";
import logoBlack from "../../img/solar-logo.svg";
import logoWhite from "../../img/solar-logo-white.svg";
import SubNavbar from "./SubNavbar";
import ThemeContext from "../../context/ThemeContext";

function Navbar() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    const { pageBanner } = useContext(ThemeContext);

    const matchZonnepanelen = useRouteMatch({
        path: [
            "/zonnepanelen/",
            "/zonnepanelen/voordelen/",
            "/zonnepanelen/installeren/",
            "/zonnepanelen/zakelijk/",
            "/zonnepanelen/tuinders/",
            "/zonnepanelen/btw/",
            "/zonnepanelen/subsidie/",
            "/producten",
        ],
        exact: true,
        exact: true,
    });
    const matchThuisbatterij = useRouteMatch({
        path: ["/thuisbatterij/", "/thuisbatterij/hoe-werkt-het", "/thuisbatterij/installatie/", "/thuisbatterij/btwterugvraag/"],
    });
    const matchLaadpalen = useRouteMatch("/laadpalen/");
    const matchProducten = useRouteMatch("/producten/");
    const matchEms = useRouteMatch("/ems/");
    const matchSupport = useRouteMatch("/support/");

    const throttle = (func, limit) => {
        let lastFunc;
        let lastRan;
        return function () {
            const context = this;
            const args = arguments;
            if (!lastRan) {
                func.apply(context, args);
                lastRan = Date.now();
            } else {
                clearTimeout(lastFunc);
                lastFunc = setTimeout(function () {
                    if (Date.now() - lastRan >= limit) {
                        func.apply(context, args);
                        lastRan = Date.now();
                    }
                }, limit - (Date.now() - lastRan));
            }
        };
    };

    const handleScroll = useCallback(
        throttle(() => {
            const isCurrentlyScrolled = window.scrollY >= 20;
            setIsScrolled(isCurrentlyScrolled);
        }, 100),
        [isScrolled]
    );

    const handleResize = useCallback(() => {
        const isBiggerThan1024 = window.innerWidth <= 1024;
        setIsMobile(isBiggerThan1024);
        if (isMenuOpen && !isBiggerThan1024) {
            setIsMenuOpen(false);
        }
    }, [isMenuOpen]);

    useEffect(() => {
        handleScroll();
        handleResize();
    }, [handleResize, handleScroll]);

    const closeMenu = useCallback(() => {
        setIsMenuOpen(false);
    }, []);

    useEffect(() => {
        handleResize();
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize, handleScroll]);

    useEffect(() => {
        closeMenu();
    }, [location, closeMenu]);

    return (
        <>
            <div className={`navbar-container ${isScrolled || isMobile ? "navbar-scrolled" : "navbar-not-scrolled"}`}>
                <nav className="navbar navbar-light">
                    <a className="navbar-brand" href="/">
                        <img src={isScrolled || isMobile ? logoBlack : logoWhite} className="logo" alt="Logo" />
                    </a>
                    <ul className={`navbar-nav ${isMobile ? "hidden" : ""}`}>
                        <NavbarLink to="/zonnepanelen/" active={matchZonnepanelen}>
                            Zonnepanelen
                        </NavbarLink>
                        <NavbarLink to="/thuisbatterij/">Thuisbatterij</NavbarLink>
                        <NavbarLink to="/laadpalen/" active={matchLaadpalen}>
                            Laadpalen
                        </NavbarLink>
                        <NavbarLink to="/ems/">EMS</NavbarLink>
                        <NavbarLink to="/support/">Support</NavbarLink>
                    </ul>
                    <div className={`hamburger-menu ${isMobile ? "" : "hidden"}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <span className="hamburger-icon">☰</span>
                    </div>
                </nav>
                {isMobile && (
                    <ul className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
                        <NavbarLink to="/zonnepanelen/" active={matchZonnepanelen}>
                            Zonnepanelen
                        </NavbarLink>
                        <NavbarLink to="/thuisbatterij/">Thuisbatterij</NavbarLink>
                        <NavbarLink to="/laadpalen/">Laadpalen</NavbarLink>
                        <NavbarLink to="/ems/">EMS</NavbarLink>
                        <NavbarLink to="/support/">Support</NavbarLink>
                    </ul>
                )}
            </div>

            {pageBanner}

            <SubNavbar
                isMenuOpen={isMenuOpen}
                matchZonnepanelen={matchZonnepanelen}
                matchThuisbatterij={matchThuisbatterij}
                matchLaadpalen={matchLaadpalen}
                matchProducten={matchProducten}
                matchEms={matchEms}
                matchSupport={matchSupport}
            />
        </>
    );
}

export default React.memo(Navbar);
