import React from "react";
import {
    Link,
    useRouteMatch
} from "react-router-dom";

import Helmet from "../../components/Helmet";
import NavbarSettings from "../../components/navbar/NavbarSettings";
import SubPageBanner from "../../components/SubPageBanner";
import SupportWidget from "../../components/support/SupportWidget";
import SupportLink from "./components/SupportLink";
import HomepageImageText from "../../components/HomepageImageText";

import banner from "../../img/solar-banner.jpg";
import ramik from "../../img/medewerkers/ramik.png";
import tplinkManual from "../../files/manuals/tplink-re305-manual.pdf";

function Support() {
    const supportHome = useRouteMatch({ path: "/support", exact: true });
    return (
        <React.Fragment>
            <Helmet title="Support" description="Heeft u problemen met uw wifi? Dat lossen we samen op! Ondersteuning voor SolarEdge, Afore en Solis omvormers."/>

            <NavbarSettings>
                <SubPageBanner title="Support" image={banner} imgAlt="Afbeelding van zonnepanelen" />
            </NavbarSettings>

            { supportHome && (
                <React.Fragment>
                    <div className="container text-center my-5">
                        <h2>Veelgestelde vragen</h2>
                        <div className="row">
                            <div className="col-md mb-2">
                                <SupportLink
                                    to="/support/monitoring/"
                                    icon="fa-solid fa-wifi"
                                    title="Monitoring"
                                />
                            </div>
                            <div className="col-md mb-2">
                                <SupportLink
                                    to="/support/storing/"
                                    icon="fa-solid fa-plug-circle-exclamation"
                                    title="Storing"
                                />
                            </div>
                            <div className="col-md mb-2">
                                <SupportLink
                                    to="/support/schade/"
                                    icon="fa-solid fa-house-chimney-crack"
                                    title="Schade"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container text-center mt-5 mb-3">
                        <h2>Heeft u problemen met uw wifi?</h2>
                        <p className="text-muted" style={ { fontSize: "1.5rem" } }>Dat lossen we samen op!</p>
                    </div>
                </React.Fragment>
            ) }
            <div className="container text-center mt-3 mb-5">
                <div className="bg-white mb-5 p-5" style={ { borderRadius: "10px", minHeight: "450px" } }>
                    <SupportWidget/>
                </div>
            </div>

            <div className="container-white-background py-0">
                <div className="container">
                    <div className="image-text-container reverse">
                        <div className="text-container">
                            <h2 className="title"> Komt u er zelf niet uit?</h2>
                            <div className="text">Geven bovenstaande vragen geen antwoord op uw vraag? Neem contact met ons op via de knop hieronder!</div>
                                <div>
                                    <Link to="/contact/" className="btn btn-primary">
                                        Neem contact op
                                    </Link>
                                </div>
                        </div>
                        <div className="image-container ramik">
                            <img src={ramik} alt="Afbeelding van storingsmedewerker" className="contain"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container text-center my-5">
                <h2>Andere vraag?</h2>
                <div className="d-flex flex-column">
                    <div className="w-100 mx-auto mb-2">
                        <Link to="/energieleveren/" className="btn btn-light text-primary">
                            Handleiding energieleveren.nl
                        </Link>
                    </div>
                    <div className="w-100 mx-auto mb-2">
                        <a href={ tplinkManual } className="btn btn-light text-primary" target="_blank" rel="noopener noreferrer">
                            Handleiding TP-Link RE305 repeater
                        </a>
                    </div>
                    <div className="w-100 mx-auto mb-2">
                        <Link to="/support/conformiteitscertificaat/" className="btn btn-light text-primary">
                            Conformiteitscertificaten omvormers
                        </Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Support;
