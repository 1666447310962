import React from "react";
import { Link } from "react-router-dom";

const HomepageImageText = ({ title, text, buttonText, buttonLink, img, isReverse, underLinedTitle = true, imgAlt = "", externalLink = false, coverImage = false, titleTag = "h2" }) => {
    const TitleTag = titleTag;
    return (
        <div className={`image-text-container ${isReverse ? "reverse" : ""}`}>
            <div className="text-container">
                {title && (
                    <TitleTag className={`title ${underLinedTitle ? "title-border" : ""}`}> {title}</TitleTag>
                )}
                <div className="text">{text}</div>
                {buttonText &&
                    <div>
                        {externalLink ? (
                            <a href={externalLink} className="btn btn-primary" target="_blank" rel="noreferrer">
                                {buttonText}
                            </a>
                        ) : (
                            <Link to={buttonLink} className="btn btn-primary">
                                {buttonText}
                            </Link>
                        )}
                    </div>
                }
            </div>
            <div className="image-container">
                <img src={img} alt={imgAlt} className={`${coverImage ? "cover" : "contain"}`}/>
            </div>
        </div>
    );
};

export default React.memo(HomepageImageText);
