import React, { useContext, useEffect } from "react";
import ThemeContext from "../../context/ThemeContext";
import { useLocation } from "react-router-dom";

const NavbarSettings = ({ children = undefined }) => {
    const { setPageBanner } = useContext(ThemeContext);
    const location = useLocation();

    useEffect(() => {
        if (children) {
            setPageBanner(location.pathname, children);
        } else {
            setPageBanner(location.pathname, null);
        }
    }, [children, setPageBanner, location.pathname]);

    return null;
};

export default React.memo(NavbarSettings);
