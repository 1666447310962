import React, {
    useMemo
} from "react";
import {
    Helmet as ReactHelmet
} from "react-helmet";

function Helmet({
                    title = null,
                    description = "Smith Solar B.V. is specialist in groene energie zoals zonnepanelen, energieopslag en laadpalen. Ontdek het aanbod online en vraag direct een offerte aan!",
                    canonicalUrls = []
}) {
    const generatedTitle = useMemo(() => {
        if(!title) {
            return "Smith Solar B.V. - Specialist in zonne-energie";
        }
        return title + " - Smith Solar B.V.";
    }, [title]);
    return (
        <ReactHelmet>
            <title>
                { generatedTitle }
            </title>
            <meta name="description" content={ description }/>
            { canonicalUrls.map((url, index) => (
                <link rel="canonical" href={ url } key={ index }/>
            )) }

            <meta property="og:site_name" content="Smith Solar B.V."/>
            <meta property="og:title" content={ title }/>
            <meta property="og:description" content={ description }/>
            <meta property="og:locale" content="nl_NL"/>

            <meta name="twitter:site" content="@smithsolar"/>
            <meta name="twitter:title" content={ title }/>
            <meta name="twitter:description" content={ description }/>
            <meta name="twitter:card" content="summary"/>
        </ReactHelmet>
    );
}

export default Helmet;
